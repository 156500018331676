/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTimer } from 'use-timer';
import { intervalToDuration } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';

import { confirmRegistration, resendConfirmation } from '../../../services/apis/directory/user';

import FormBuilder from '../../../helper/formBuilder';

import Schema from '../../../schema/form/auth/confirmRegistration';

import Button from '../../../components/button';

function ConfirmRegistration() {
  const location = useLocation();
  const navigate = useNavigate();

  const { time, start, reset, status } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
  });

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: Schema.DefaultValues,
    resolver: yupResolver(Schema.YupSchema),
  });

  useEffect(() => {
    start();
  }, []);

  const confirm = useCallback(async data => {
    setLoading(true);

    try {
      await confirmRegistration(data);

      setLoading(false);
      navigate('/login');
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const resend = useCallback(async (email, token) => {
    try {
      await resendConfirmation({
        email,
        token,
        abandoned: false,
      });

      reset();
      start();
    } catch (_error) {
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback(data => {
    const { token = null } = queryString.parse(location.search);

    confirm({
      token,
      ...data,
    });
  }, []);

  const duration = intervalToDuration({ start: 0, end: time * 1000 });

  return (
    <div className="mx-auto flex overflow-y-auto pt-6 pb-4">
      <div className="max-w-sm w-full mx-auto bg-white shadow-lg rounded-md border border-gray-300">
        <div className="overflow-hidden">
          <div className="pb-6 pt-6 pr-8 pl-8">
            <h1 className="text-xl text-black text-center mb-5 font-medium">
              Confirm Registration
            </h1>
            <p className="text-sm font-light">
              Please enter the One-Time Password sent to the registered email
            </p>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {FormBuilder({
                template: Schema.Template,
                control,
              }).map(({ field }, index) => (
                <div key={index} className="mb-4">
                  {field}
                </div>
              ))}
              <div className="flex items-center justify-between mb-4">
                <span>
                  {`${duration.minutes.toString().padStart(2, '0')}:${duration.seconds
                    .toString()
                    .padStart(2, '0')}`}
                </span>
                <button
                  type="button"
                  className="button--text-only"
                  onClick={() => {
                    if (status === 'STOPPED') {
                      const { token = null } = queryString.parse(location.search);

                      resend(null, token);
                    }
                  }}
                >
                  Resend Code
                </button>
              </div>
              <Button label="Confirm" loading={loading} disabled={loading} fullWidth />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmRegistration;
