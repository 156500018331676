/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormBuilder from '../../../../../helper/formBuilder';

import { TeamTemplate } from '../../../../../helper/schema/novoHealthRelay';

function TeamInformation(props) {
  const { control, namesList } = props;

  const [template, setTemplate] = useState(TeamTemplate);

  useEffect(() => {
    const cleanedNames = namesList
      .filter(name => name !== null)
      .map(name => ({
        id: name,
        name,
      }));

    const fields = [...template];
    const teamCaptain = fields.find(field => field.name === 'teamCaptain');
    const index = fields.findIndex(field => field.name === 'teamCaptain');

    teamCaptain.options = cleanedNames;

    fields.splice(index, 1, teamCaptain);

    setTemplate(fields);
  }, [namesList]);

  return (
    <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mb-5">
      <h1 className="mb-6 text-lg font-bold">Team Information</h1>
      <div className="grid grid-cols-12 gap-5 row-gap-5">
        {FormBuilder({
          template: {
            fields: template,
          },
          control,
        }).map(
          ({ field }, index) =>
            field && (
              <div key={index} className="col-span-12 sm:col-span-6">
                {field}
              </div>
            ),
        )}
      </div>
    </div>
  );
}

TeamInformation.propTypes = {
  control: PropTypes.any.isRequired,
  namesList: PropTypes.any.isRequired,
};

export default TeamInformation;
