/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import { kebabCase, lowerCase } from 'lodash';
import { format } from 'date-fns';
import { Phone, Cake, GenderMale, GenderFemale, GenderNonbinary, Camera } from 'phosphor-react';
import ProgressiveImage from 'react-progressive-graceful-image';
import SVG from 'react-inlinesvg';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-initials-sprites';
import PropTypes from 'prop-types';

import Spinner from '../../../loader/spinner';

import { dataURLtoFile } from '../../../../helper/utility';

function Sidebar({ user, uploading, onImageChange }) {
  const [svg, setSVG] = useState(null);
  const [image, setImage] = useState(null);

  const name = `${user.firstName} ${user.lastName}`;

  useEffect(() => {
    const avatars = new Avatars(sprites, {});

    setSVG(avatars.create(kebabCase(lowerCase(name))));
  }, []);

  const handleImageChange = useCallback(event => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      const data = new FormData();
      data.append('image', dataURLtoFile(fileReader.result, 'Image'));

      onImageChange(data);

      setImage({
        file,
        uri: fileReader.result,
      });
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  });

  return (
    <div className="flex flex-col items-center justify-center py-6 px-2 bg-white shadow-lg rounded-md border border-gray-300">
      <div className="relative w-24 h-24">
        {user.picture || image ? (
          <ProgressiveImage
            delay={1000}
            src={user.picture || image?.uri}
            placeholder={`${user.picture}?tr=w-100,bl-10`}>
            {src => (
              <img src={src} alt="user profile" className="w-24 h-24 object-cover rounded-full" />
            )}
          </ProgressiveImage>
        ) : (
          <SVG src={svg} className="w-24 h-24 shadow-md rounded-full" />
        )}
        {uploading ? (
          <div className="absolute top-0 left-0 w-24 h-24 flex items-center bg-black opacity-50 rounded-full">
            <Spinner size="small" color="white" />
          </div>
        ) : null}
        <div className="absolute items-center justify-center bottom-0 right-0 bg-black shadow-md rounded-full cursor-pointer p-2">
          <label htmlFor="profile-image">
            <Camera size={18} color="white" className="cursor-pointer" />
          </label>
          <input
            id="profile-image"
            accept="image/*"
            hidden
            type="file"
            onChange={handleImageChange}
            disabled={uploading}
          />
        </div>
      </div>
      <div className="mt-4 text-sm text-left">
        <div className="flex items-center justify-center">
          <p className="ml-2 text-lg font-medium">{name}</p>
        </div>
        <div className="flex items-center justify-center mb-5">
          <p className="ml-2 text-gray-600">{user.email}</p>
        </div>
        <div className="flex items-center justify-center sm:justify-start mb-2">
          <Phone size={20} />
          <p className="ml-2">{user.phoneNumber}</p>
        </div>
        <div className="flex items-center justify-center sm:justify-start mb-2">
          <Cake size={20} />
          <p className="ml-2">{format(user.dob, 'dd/MM/yyyy')}</p>
        </div>
        <div className="flex items-center justify-center sm:justify-start">
          {user.gender === 'Male' ? (
            <GenderMale size={20} />
          ) : user.gender === 'Female' ? (
            <GenderFemale size={20} />
          ) : (
            <GenderNonbinary size={20} />
          )}
          <p className="ml-2">{user.gender}</p>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  uploading: PropTypes.bool.isRequired,
  onImageChange: PropTypes.func.isRequired,
};

export default Sidebar;
