import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from '../../components/loader/spinner';

function PublicRoute({ children, restricted }) {
  const session = useSelector(state => state.session);

  if (session.loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  return session.logged && restricted ? <Navigate to="/races" replace /> : children;
}

PublicRoute.defaultProps = {
  restricted: false,
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
  restricted: PropTypes.bool,
};

export default PublicRoute;
