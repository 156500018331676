import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';

import Home from '../pages/static/home';

import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import ForgotPassword from '../pages/auth/forgotPassword';
import ConfirmRegistration from '../pages/auth/confirmRegistration';

import Dashboard from '../pages/user/dashboard';
import Profile from '../pages/user/profile';

import RaceList from '../pages/races/list';
import RaceDetails from '../pages/races/details';
import RaceRegister from '../pages/races/register';
import RaceSuccess from '../pages/races/success';

import ResultRaces from '../pages/results/races';
import ResultList from '../pages/results/list';
import ResultPhotos from '../pages/results/photos';
import ResultDetails from '../pages/results/details';

import NovoHealth from '../pages/custom/novoHealth';
import NovoHealthDashboard from '../pages/custom/novoHealth/dashboard';
import NovoHealthSearch from '../pages/custom/novoHealth/search';
import NovoHealthRelayRegister from '../pages/custom/novoHealth/relay-register';

import About from '../pages/static/about';
import Contact from '../pages/static/contact';
import Terms from '../pages/static/terms';
import Privacy from '../pages/static/privacy';

import NotFoundError from '../pages/error/404';

const Router = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <PublicRoute>
          <Home />
        </PublicRoute>
      ),
    },
    {
      path: '/login',
      element: (
        <PublicRoute restricted>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: '/register',
      element: (
        <PublicRoute restricted>
          <Register />
        </PublicRoute>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <PublicRoute restricted>
          <ForgotPassword />
        </PublicRoute>
      ),
    },
    {
      path: '/confirm-registration',
      element: (
        <PublicRoute restricted>
          <ConfirmRegistration />
        </PublicRoute>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: '/profile',
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
    {
      path: '/races',
      element: (
        <PublicRoute>
          <RaceList />
        </PublicRoute>
      ),
    },
    {
      path: '/event/:url',
      element: (
        <PublicRoute>
          <RaceDetails />
        </PublicRoute>
      ),
    },
    {
      path: '/race/:url',
      element: (
        <PublicRoute>
          <RaceDetails />
        </PublicRoute>
      ),
    },
    {
      path: '/race/:url/register',
      element: (
        <PublicRoute>
          <RaceRegister />
        </PublicRoute>
      ),
    },
    {
      path: '/race/:url/relay-register',
      element: (
        <PublicRoute>
          <NovoHealthRelayRegister />
        </PublicRoute>
      ),
    },
    {
      path: '/race/:url/success',
      element: (
        <PublicRoute>
          <RaceSuccess />
        </PublicRoute>
      ),
    },
    {
      path: '/results',
      element: (
        <PublicRoute>
          <ResultRaces />
        </PublicRoute>
      ),
    },
    {
      path: '/results/list',
      element: (
        <PublicRoute>
          <ResultList />
        </PublicRoute>
      ),
    },
    {
      path: '/results/photos',
      element: (
        <PublicRoute>
          <ResultPhotos />
        </PublicRoute>
      ),
    },
    {
      path: '/results/details',
      element: (
        <PublicRoute>
          <ResultDetails />
        </PublicRoute>
      ),
    },
    {
      path: '/novoHealth',
      element: (
        <PublicRoute restricted>
          <NovoHealth />
        </PublicRoute>
      ),
    },
    {
      path: '/novoHealth/dashboard',
      element: (
        <PublicRoute restricted>
          <NovoHealthDashboard />
        </PublicRoute>
      ),
    },
    {
      path: '/novoHealth/search',
      element: (
        <PublicRoute restricted>
          <NovoHealthSearch />
        </PublicRoute>
      ),
    },
    {
      path: '/about-us',
      element: (
        <PublicRoute>
          <About />
        </PublicRoute>
      ),
    },
    {
      path: '/contact-us',
      element: (
        <PublicRoute>
          <Contact />
        </PublicRoute>
      ),
    },
    {
      path: '/terms-conditions',
      element: (
        <PublicRoute>
          <Terms />
        </PublicRoute>
      ),
    },
    {
      path: '/privacy-policy',
      element: (
        <PublicRoute>
          <Privacy />
        </PublicRoute>
      ),
    },
    {
      path: '/page-not-found',
      element: (
        <PublicRoute>
          <NotFoundError />
        </PublicRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to="/page-not-found" />,
    },
  ]);

  return routes;
};

export default Router;
