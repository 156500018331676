/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import { usePopper } from 'react-popper';
import { useController } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../loader/spinner';

function DropdownField({ control, name, label, tooltip, options, loading, disabled, required }) {
  const [visible, setVisibility] = useState(false);
  const [selectElement, setSelectElement] = useState(null);
  const [optionsElement, setOptionsElement] = useState(null);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn({ state }) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect({ state }) {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
        },
      },
    ],
    [],
  );

  const { styles, attributes } = usePopper(selectElement, optionsElement, {
    modifiers,
  });

  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisibility(false);
      }}>
      <div className="dropdown-field">
        {label && (
          <label htmlFor={name} className="text-sm font-bold text-gray-800">
            {label}{' '}
            {required && (
              <span className="text-red-600" style={{ marginLeft: '2px' }}>
                *
              </span>
            )}
            {tooltip && (
              <Tooltip title={tooltip}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  style={{ marginLeft: '2px' }}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Tooltip>
            )}
          </label>
        )}
        <div>
          {visible && (
            <div
              ref={setOptionsElement}
              style={{ zIndex: 1, ...styles.popper }}
              className="options-container"
              {...attributes.popper}>
              <div
                style={{
                  maxHeight: '270px',
                }}
                className="bg-white overflow-hidden order-1 overflow-y-auto text-sm shadow-lg rounded-md border border-gray-300">
                {options.map((item, index) => (
                  <div
                    key={index}
                    className={classNames(
                      'option flex justify-between items-center px-4 py-3 cursor-pointer hover:bg-gray-100 transition-colors duration-700',
                      item.disabled ? 'bg-gray-300 cursor-not-allowed pointer-events-none' : null,
                    )}
                    onClick={() => {
                      field.onChange(item);
                      setVisibility(false);
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0">
                    <input id={item.id} name={item.name} type="radio" className="radio hidden" />
                    <label htmlFor={item.id} className="cursor-pointer">
                      {item.name}
                    </label>
                    {item.suffix ? (
                      <div className="px-2 py-1 bg-primary-500 rounded-full">
                        <span className="text-white text-sm">{item.suffix}</span>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="relative">
          <input
            ref={setSelectElement}
            className={classNames(
              'w-full pl-2 pr-16 border placeholder-gray-600 text-base cursor-pointer select-none rounded',
              fieldState.error ? 'border-red-600' : 'border-gray-300',
            )}
            value={field.value?.name || ''}
            readOnly
            disabled={disabled}
            onClick={() => setVisibility(!visible)}
          />
          <div className="absolute inset-y-0 right-0 pl-2 pr-2 flex items-center">
            {loading ? (
              <Spinner size="xs" color="black" />
            ) : visible ? (
              <svg
                className="w-4 h-4 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility(!visible)}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-4 h-4 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility(!visible)}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </div>
        </div>
        {fieldState.error && (
          <p className="text-red-600 text-xs text-left font-semibold">
            {fieldState.error?.message}
          </p>
        )}
      </div>
    </OutsideClickHandler>
  );
}

DropdownField.defaultProps = {
  label: null,
  tooltip: null,
  loading: false,
  disabled: false,
  required: false,
};

DropdownField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default DropdownField;
