/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Pagination({ currentPage, step, metadata, onChange }) {
  const showingNumbers = step * 2 + 1;
  let startNumber = 2;
  let startArrayNumber = step;

  let needStartDots = false;
  let needEndDots = false;

  if (currentPage > step) {
    startArrayNumber = currentPage - step;

    needStartDots = currentPage > step + startNumber;
  }

  if (metadata?.totalPages > showingNumbers) {
    needEndDots = metadata?.totalPages > currentPage + step + 1;

    if (metadata?.totalPages < currentPage + step + 1) {
      startArrayNumber = metadata?.totalPages - showingNumbers;
    }
  }

  let contentNumber;

  return (
    <div className="flex justify-center">
      <ul className="pagination">
        <li
          className={classNames('page-item prev arrow-icon', currentPage <= 1 && 'disabled')}
          role="presentation"
          onClick={() => onChange(currentPage - 1)}>
          &#x2039;
        </li>
        {metadata?.totalPages > showingNumbers + startNumber ? (
          <>
            <li
              role="presentation"
              className={`page-item ${currentPage === 1 && 'active'}`}
              onClick={event => onChange(event.target.textContent)}>
              1
            </li>
            {needStartDots && <span>...</span>}
            {_.times(showingNumbers, i => (
              <li
                key={i++}
                {...(contentNumber = needStartDots ? startArrayNumber : startNumber)}
                {...startNumber++}
                {...startArrayNumber++}
                className={`page-item ${currentPage === contentNumber && 'active'}`}
                role="presentation"
                onClick={event => onChange(event.currentTarget.textContent)}>
                {contentNumber}
              </li>
            ))}
            {needEndDots && <span>...</span>}
            <li
              role="presentation"
              className={`page-item ${currentPage === metadata?.totalPages && 'active'}`}
              onClick={event => onChange(event.currentTarget.textContent)}>
              {metadata?.totalPages}
            </li>
          </>
        ) : (
          ((startArrayNumber = 1),
          _.times(metadata?.totalPages, i => (
            <li
              key={i++}
              role="presentation"
              className={`page-item ${currentPage === startArrayNumber && 'active'}`}
              onClick={event => onChange(event.currentTarget.textContent)}>
              {startArrayNumber++}
            </li>
          )))
        )}
        <li
          className={classNames(
            'page-item next arrow-icon',
            currentPage >= metadata?.totalPages && 'disabled',
          )}
          role="presentation"
          onClick={() => onChange(currentPage + 1)}>
          &#8250;
        </li>
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  metadata: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
