/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  INPUT_FIELD,
  INPUT_MASK_FIELD,
  TEXT_AREA_FIELD,
  PASSWORD_FIELD,
  RADIO_GROUP_FIELD,
  DROPDOWN_FIELD,
  CHECKBOX_FIELD,
  RICH_TEXT_FIELD,
  SOCIAL_LINKS_FIELD,
} from '../../constants/form/types';

import InputField from '../../components/fields/input';
import InputMaskField from '../../components/fields/inputMask';
import TextAreaField from '../../components/fields/textarea';
import PasswordField from '../../components/fields/password';
import RadioGroupField from '../../components/fields/radioGroup';
import DropdownField from '../../components/fields/dropdown';
import CheckboxField from '../../components/fields/checkbox';
import RichTextField from '../../components/fields/richText';
import SocialLinksField from '../../components/custom/fields/socialLinks';

const FormBuilder = props => {
  const { template, control } = props;
  const { fields } = template;

  return fields.map((field, index) => {
    const {
      type,
      name,
      label,
      padding,
      paddingText,
      mask,
      tooltip,
      options,
      column,
      onKeyDown,
      disabled,
      hidden,
      required,
      hide,
    } = field;

    switch (type) {
      case INPUT_FIELD:
        return {
          field:
            hidden || hide ? null : (
              <InputField
                key={index}
                name={name}
                control={control}
                label={label}
                padding={padding}
                paddingText={paddingText}
                tooltip={tooltip}
                onKeyDown={onKeyDown}
                disabled={disabled}
                required={required}
              />
            ),
          type,
        };

      case INPUT_MASK_FIELD:
        return {
          field: (
            <InputMaskField
              key={index}
              name={name}
              control={control}
              label={label}
              mask={mask}
              tooltip={tooltip}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case TEXT_AREA_FIELD:
        return {
          field: (
            <TextAreaField
              key={index}
              name={name}
              control={control}
              label={label}
              mask={mask}
              tooltip={tooltip}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case PASSWORD_FIELD:
        return {
          field: (
            <PasswordField
              key={index}
              name={name}
              control={control}
              label={label}
              tooltip={tooltip}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case RADIO_GROUP_FIELD:
        return {
          field: hide ? null : (
            <RadioGroupField
              key={index}
              name={name}
              control={control}
              label={label}
              tooltip={tooltip}
              options={options}
              column={column}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case DROPDOWN_FIELD:
        return {
          field: (
            <DropdownField
              key={index}
              name={name}
              control={control}
              label={label}
              tooltip={tooltip}
              options={options}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case CHECKBOX_FIELD:
        return {
          field: (
            <CheckboxField
              key={index}
              name={name}
              control={control}
              label={label}
              tooltip={tooltip}
              disabled={disabled}
              required={required}
            />
          ),
          type,
        };

      case RICH_TEXT_FIELD:
        return {
          field: (
            <RichTextField
              key={index}
              name={name}
              control={control}
              label={label}
              tooltip={tooltip}
              required={required}
            />
          ),
          type,
        };

      case SOCIAL_LINKS_FIELD:
        return {
          field: <SocialLinksField key={index} name={name} control={control} label={label} />,
          type,
        };

      default:
        return null;
    }
  });
};

export default FormBuilder;
