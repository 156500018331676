/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { parse } from 'date-fns';

import store from '../../redux/store';

import { sessionActive } from '../../redux/reducer/session';
import { notificationSuccess, notificationError } from '../../redux/saga/notification/actions';

import { refreshSession } from '../cognito';

const StatusCode = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  TooManyRequests: 429,
  InternalServerError: 500,
};

const injectToken = config => {
  const {
    session: { accessToken },
  } = store.getState();

  if (config.headers === undefined) {
    config.headers = {};
  }

  if (accessToken) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
};

const getRefreshToken = () => store.getState().session.refreshToken;

class Http {
  get http() {
    return this.instance != null ? this.instance : this.init();
  }

  init() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
    });

    client.interceptors.request.use(
      config => injectToken(config),
      error => Promise.reject(error),
    );

    createAuthRefreshInterceptor(
      client,
      async failedRequest => {
        const session = await refreshSession(getRefreshToken());

        const newRefreshToken = session.getRefreshToken().getToken();
        const newAccessToken = session.getAccessToken().getJwtToken();
        const newIdToken = session.getIdToken().getJwtToken();
        const newUser = session.getIdToken().payload;

        store.dispatch(
          sessionActive({
            refreshToken: newRefreshToken,
            accessToken: newAccessToken,
            idToken: newIdToken,
            user: {
              id: newUser.sub,
              firstName: newUser.given_name,
              lastName: newUser.family_name,
              email: newUser.email,
              emailVerified: newUser.email_verified,
              phoneNumber: newUser.phone_number,
              phoneNumberVerified: newUser.phone_number_verified,
              dob: parse(newUser.birthdate, 'dd/MM/yyyy', new Date()),
              gender: newUser.gender,
              picture: newUser.picture || null,
            },
          }),
        );

        failedRequest.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
        return Promise.resolve();
      },
      {
        statusCodes: [StatusCode.Unauthorized],
        pauseInstanceWhileRefreshing: true,
      },
    );

    client.interceptors.response.use(
      response => {
        const message = response.data?.message;
        const data = response.data?.data;

        if (message) {
          store.dispatch(notificationSuccess(message));
        }

        return data;
      },
      error => {
        if (error.response) {
          this.handleError(error.response);
        } else {
          store.dispatch(notificationError(error?.message || 'Something went wrong'));
        }

        return Promise.reject(error);
      },
    );

    this.instance = client;
    return client;
  }

  request(config) {
    return this.http.request(config);
  }

  get(url, config) {
    return this.http.get(url, config);
  }

  post(url, data, config) {
    return this.http.post(url, data, config);
  }

  put(url, data, config) {
    return this.http.put(url, data, config);
  }

  delete(url, config) {
    return this.http.delete(url, config);
  }

  patch(url, data, config) {
    return this.http.patch(url, data, config);
  }

  handleError(error) {
    const { data, status } = error;

    switch (status) {
      case StatusCode.BadRequest:
        store.dispatch(notificationError(data?.message || 'Something went wrong'));
        break;
      case StatusCode.InternalServerError:
        store.dispatch(notificationError(data?.message || 'Something went wrong'));
        break;
      default:
        break;
    }
  }
}

const http = new Http();

export default http;
