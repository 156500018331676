import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Spinner from '../../../../components/loader/spinner';

import EventSelection from './components/event-selection';
import Runner from './components/runner';
import TeamInformation from './components/team-information';
import Consent from './components/consent';
import Summary from './components/summary';

import {
  fetchCalendarRaceDetails,
  fetchCalendarRaceForm,
  fetchRaceEvents,
} from '../../../../services/apis/business/race';

import { createNovoHealthRegistration } from '../../../../services/apis/business/registration';

import { YupSchema } from '../../../../helper/schema/novoHealthRelay';

import { cleanObject } from '../../../../helper/utility';

import { notificationError } from '../../../../redux/saga/notification/actions';

const MAX_USERS = 5;

const ids = {
  'MENS RELAY (5K) (₹0)': 'b288bc94-ba90-49f2-ae18-2612d4517838',
  'WOMENS RELAY (5K) (₹0)': '20c15848-da68-4bfa-8fd9-0d5f791e5fbd',
  'MIXED RELAY (5K) (₹0)': '3b889379-a25e-4c26-8483-c6a99b62258c',
};

function RelayRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { url } = useParams();

  const { user } = useSelector(state => state.session);

  const [userForms, setUserForms] = useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ]);
  const [details, setDetails] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [events, setEvents] = useState([]);
  const [totalDependency, setTotalDependency] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registering, setRegistering] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(YupSchema),
  });

  const formData = useWatch({ control });

  const raceDetails = useCallback(async raceUrl => {
    const response = await fetchCalendarRaceDetails(raceUrl);

    setDetails(response);
  }, []);

  const raceForm = useCallback(async raceUrl => {
    const response = await fetchCalendarRaceForm(raceUrl);

    setLoading(false);
    setParticipant(response.participant);
  }, []);

  const raceEvents = useCallback(async raceUrl => {
    const response = await fetchRaceEvents(raceUrl);

    setEvents(response.eventsList);
  }, []);

  useEffect(() => {
    raceDetails(url).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceDetails]);

  useEffect(() => {
    raceForm(url).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceForm]);

  useEffect(() => {
    if (!details) {
      return;
    }

    raceEvents(details.id).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceEvents, details]);

  const registerParticipant = useCallback(async data => {
    setRegistering(true);

    try {
      const response = await createNovoHealthRegistration(data);
      const { success } = response;

      setRegistering(false);

      if (success) {
        navigate(`/race/${url}/success`);
      }
    } catch (error) {
      setRegistering(false);
    }
  }, []);

  useEffect(() => {
    if (participant) {
      setTotalDependency(['event']);

      reset({
        ...participant.defaultValue,
        waiver: false,
      });
    }
  }, [participant]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let errormessage = '';

      if (errors.sameDomain) {
        errormessage += `${errors.sameDomain.message}\n`;
      }

      if (errors.uniquePhones) {
        errormessage += `${errors.uniquePhones.message}\n`;
      }

      if (errors.mixedGender) {
        errormessage += `${errors.mixedGender.message}\n`;
      }

      if (errormessage !== '') {
        dispatch(notificationError(errormessage));
      }
    }
  }, [errors]);

  const addUserForm = () => {
    if (userForms.length < MAX_USERS) {
      setUserForms([...userForms, { id: userForms.length + 1 }]);
    }
  };

  const removeUserForm = id => {
    setUserForms(userForms.filter(form => form.id !== id));
  };

  useEffect(() => {
    if (Object.keys(formData).length) {
      setTotal(
        totalDependency.map(dependency => {
          const x = formData[dependency];

          if (!x) {
            return {};
          }

          return {
            id: ids[x],
            name: x,
            fee: 0,
          };
        }),
      );
    }
  }, [formData, totalDependency]);

  const onSubmit = useCallback(
    data => {
      const registration = {
        ...data,
        'tShirt-1': data['tShirt-1']?.name,
        'tShirt-2': data['tShirt-2']?.name,
        'tShirt-3': data['tShirt-3']?.name,
        'tShirt-4': data['tShirt-4']?.name,
        'tShirt-5': data['tShirt-5']?.name,
        teamCaptain: data.teamCaptain.name,
      };

      const totalIDs = total
        .map(item => item.id)
        .filter(item => item !== null && item !== undefined);

      const userID = user?.id || null;

      const payload = {
        raceID: details.id,
        userID,
        document: null,
        details: cleanObject(registration),
        ids: totalIDs,
        discount: null,
      };

      registerParticipant(payload);
    },
    [details, user, total],
  );

  if (loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{loading ? 'Loading...' : `${details.name} Registration`}</title>
      </Helmet>
      <div className="min-height">
        <div className="container max-w-4xl mx-auto px-4 py-6 md:px-8">
          <div className="flex items-baseline mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth={4}
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={{ width: '20px', height: '20px' }}
              className="cursor-pointer"
              onClick={() => navigate(-1)}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            <h1 className="text-2xl text-left font-bold ml-3">{details.name} Registration</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-5 row-gap-5">
              <div className="col-span-12">
                <EventSelection control={control} options={events} loading={loading} />
              </div>
              <div className="col-span-12">
                {false && (
                  <div className="flex justify-end mb-3">
                    <button type="button" className="button" onClick={() => addUserForm()}>
                      Add Runner
                    </button>
                  </div>
                )}
                {userForms.map(form => (
                  <Runner
                    key={form.id}
                    formId={form.id}
                    event={formData.event}
                    participant={participant}
                    control={control}
                    setValue={setValue}
                    removeUserForm={removeUserForm}
                  />
                ))}
                <TeamInformation
                  control={control}
                  namesList={[
                    formData['name-1'] || null,
                    formData['name-2'] || null,
                    formData['name-3'] || null,
                    formData['name-4'] || null,
                    formData['name-5'] || null,
                  ]}
                />
                <Consent control={control} />
              </div>
              <div className="col-span-12 sm:col-span-7" />
              <div className="col-span-12 sm:col-span-5">
                <Summary
                  id={details.id}
                  total={total}
                  gstRate={0}
                  processingFee={details.processingFee}
                  registering={registering}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default RelayRegister;
