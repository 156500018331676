/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

import EditorToolbar, { modules, formats } from './toolbar';

function RichTextField({ control, name, label, tooltip, required }) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="rich-text-field">
      <label htmlFor={name} className="text-sm font-bold text-gray-800 flex items-center">
        {label}{' '}
        {required && (
          <span className="text-red-600" style={{ marginLeft: '2px' }}>
            *
          </span>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              style={{ marginLeft: '2px' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Tooltip>
        )}
      </label>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={field.value}
        onChange={value => field.onChange(value)}
        modules={modules}
        formats={formats}
      />
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

RichTextField.defaultProps = {
  tooltip: null,
  required: false,
};

RichTextField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default RichTextField;
