import React from 'react';
import PropTypes from 'prop-types';

function SocialLinks({ name, label }) {
  return (
    <div>
      <label htmlFor={name} className="text-sm font-bold text-gray-800 flex items-center">
        {label}{' '}
      </label>
    </div>
  );
}

SocialLinks.defaultProps = {
  label: null,
};

SocialLinks.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default SocialLinks;
