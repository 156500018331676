/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function PasswordField({ control, name, label, tooltip, disabled, required }) {
  const [visible, setVisibility] = useState(false);

  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="password-field">
      <label htmlFor={name} className="text-sm font-bold text-gray-800">
        {label}{' '}
        {required && (
          <span className="text-red-600" style={{ marginLeft: '2px' }}>
            *
          </span>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              style={{ marginLeft: '2px' }}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Tooltip>
        )}
      </label>
      <div className="relative">
        <input
          name={name}
          type={visible ? 'text' : 'password'}
          className={classNames(
            'w-full px-2 border placeholder-gray-600 text-base rounded',
            fieldState.error ? 'border-red-600' : 'border-gray-300',
          )}
          value={field.value || ''}
          onChange={field.onChange}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          {visible ? (
            <svg
              fill="black"
              viewBox="0 0 20 20"
              className="h-6 cursor-pointer"
              onClick={() => setVisibility(!visible)}>
              <path
                fillRule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clipRule="evenodd"
              />
              <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
          ) : (
            <svg
              fill="black"
              viewBox="0 0 20 20"
              className="h-6 cursor-pointer"
              onClick={() => setVisibility(!visible)}>
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </div>
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

PasswordField.defaultProps = {
  tooltip: null,
  disabled: false,
  required: false,
};

PasswordField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default PasswordField;
