/* eslint-disable no-param-reassign */
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
  CookieStorage,
} from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  Storage: new CookieStorage({ domain: process.env.REACT_APP_DOMAIN }),
};

const userPool = new CognitoUserPool(poolData);

export const loginUser = (email, password) => {
  const authenticationData = {
    Username: email,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: email,
    Pool: userPool,
    Storage: new CookieStorage({ domain: process.env.REACT_APP_DOMAIN }),
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => resolve(result),
      onFailure: error => reject(error),
      newPasswordRequired: userAttributes => {
        delete userAttributes.email;
        delete userAttributes.phone_number;

        cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
          onSuccess: result => resolve(result),
          onFailure: error => reject(error),
        });
      },
    });
  });
};

export const getUser = () => userPool.getCurrentUser();

export const confirmUser = (email, code) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (error, result) => {
      if (error) reject(error);

      resolve(result);
    });
  });
};

export const getSession = user =>
  new Promise((resolve, reject) => {
    user.getSession((error, session) => {
      if (error) reject(error);

      resolve(session);
    });
  });

export const updateUser = async attributeList => {
  const user = await getUser();

  await getSession(user);

  return new Promise((resolve, reject) => {
    user.updateAttributes(attributeList, (error, result) => {
      if (error) reject(error);

      resolve(result);
    });
  });
};

export const refreshSession = refreshToken => {
  const token = new CognitoRefreshToken({ RefreshToken: refreshToken });

  const cognitoUser = getUser();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(token, (error, session) => {
      if (error) reject(error);

      resolve(session);
    });
  });
};

export const logoutUser = () => {
  const cognitoUser = getUser();

  if (cognitoUser) {
    cognitoUser.signOut();
  }
};
