/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { registerUser } from '../../../services/apis/directory/user';

import FormBuilder from '../../../helper/formBuilder';

import Schema from '../../../schema/form/auth/register';

import Button from '../../../components/button';

function Register() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setError } = useForm({
    defaultValues: Schema.DefaultValues,
    resolver: yupResolver(Schema.YupSchema),
  });

  const register = useCallback(async data => {
    setLoading(true);

    try {
      const response = await registerUser(data);
      const { token } = response;

      setLoading(false);
      navigate(`/confirm-registration?token=${token}`);
    } catch (error) {
      setLoading(false);

      const response = error?.response;

      if (response) {
        const fields = response.data?.fields || [];

        fields.forEach(field => {
          setError(field.name, {
            message: field.errorMessage,
          });
        });
      }
    }
  }, []);

  const onSubmit = useCallback(data => {
    const user = {
      ...data,
      country: data.country.id,
    };

    register(user);
  }, []);

  return (
    <div className="mx-auto flex h-full overflow-y-auto pt-10 pb-10">
      <div className="max-mobile-width sm:max-w-sm w-full mx-auto my-auto bg-white shadow-lg rounded-md border border-gray-300">
        <div className="overflow-hidden">
          <div className="pb-6 pt-6 pr-8 pl-8">
            <h1 className="text-xl text-black text-center mb-5 font-medium">Register</h1>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {FormBuilder({
                template: Schema.Template,
                control,
              }).map(({ field }, index) => (
                <div key={index} className="mb-4">
                  {field}
                </div>
              ))}
              <p className="text-sm mb-4">
                By clicking below to <strong>Register</strong>, you are agreeing to{' '}
                <strong>Racetime</strong>&#39;s{' '}
                <a
                  href="/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text--primary font-medium">
                  Terms
                </a>{' '}
                &amp;{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text--primary font-medium">
                  Privacy Policy
                </a>
              </p>
              <Button label="Register" loading={loading} disabled={loading} fullWidth />
              <p className="mt-5 text-center text-sm font-medium">
                Already a member?{' '}
                <Link to="/login" className="text--primary">
                  Sign In
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
