import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Spinner({ color, size }) {
  return (
    <div className={classNames('spinner', `spinner--${size}`)}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className={classNames('path')}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}

Spinner.defaultProps = {
  color: 'black',
  size: 'small',
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'small', 'medium', 'large']),
};

export default Spinner;
