/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Checkbox({ control, name, label, tooltip, disabled, required }) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="flex flex-col items-start">
      <div className={classNames('flex')}>
        <input
          id={name}
          name={name}
          type="checkbox"
          className="hidden"
          checked={field.value || false}
          onChange={field.onChange}
          disabled={disabled}
        />
        <label className="checkbox-label flex" htmlFor={name}>
          <span
            className={classNames('checkbox bg-gray-200 border-2 border-gray-600')}
            style={{ minWidth: '18px', minHeight: '18px' }}>
            <svg width="12px" height="10px" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1" />
            </svg>
          </span>
          <div className="ml-2">
            <p className="text-gray-800 text-sm font-bold">
              {label}
              {required && <span className="text-red-600 ml-1">*</span>}
            </p>
          </div>
          {tooltip && (
            <Tooltip title={tooltip}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                style={{ marginLeft: '2px' }}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </Tooltip>
          )}
        </label>
      </div>
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold mt-2">
          {fieldState.error?.message}
        </p>
      )}
    </div>
  );
}

Checkbox.defaultProps = {
  tooltip: null,
  disabled: false,
  required: false,
};

Checkbox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default Checkbox;
