/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { XCircle, MagnifyingGlass } from 'phosphor-react';
import queryString from 'query-string';

import {
  fetchResultEventsList,
  fetchResultCategoriesList,
  fetchResultsList,
  fetchResultsSearch,
} from '../../../services/apis/business/result';

import InputField from '../../../components/fields/input';
import DropdownField from '../../../components/fields/dropdown';

import Spinner from '../../../components/loader/spinner';

import Gender from '../../../data/pages/results/list/gender.json';

function ResultList() {
  const location = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [race, setRace] = useState(null);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(Gender);
  const [header, setHeader] = useState([]);
  const [results, setResults] = useState([]);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      search: '',
      event: null,
      gender: filter[0],
      category: null,
    },
  });

  const search = watch('search');
  const event = watch('event');
  const gender = watch('gender');
  const category = watch('category');

  const eventsList = useCallback(async selectedRace => {
    const response = await fetchResultEventsList(selectedRace);
    const { id, name, date, events: list } = response;

    setLoading(false);
    setRace({
      id,
      name,
      date,
    });
    setEvents(
      list.map(item => ({
        id: item,
        name: item,
      })),
    );
  }, []);

  const categoriesList = useCallback(async (raceID, selectedEvent) => {
    try {
      const response = await fetchResultCategoriesList(raceID, selectedEvent);

      const list = response.map(item => ({
        id: item,
        name: item,
      }));

      setFetching(false);
      setCategories(list);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const resultsList = useCallback(
    async (raceID, selectedEvent, selectedGender, selectedCategory, currentPage) => {
      try {
        const response = await fetchResultsList(
          raceID,
          selectedEvent,
          selectedGender,
          selectedCategory,
          currentPage,
        );

        const { filter, header, results: list } = response;

        setFilter(filter);
        setHeader(header);
        setResults(list);
        setSearched(false);
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    },
    [],
  );

  const resultsSearch = useCallback(async (raceID, eventName, searchTerm) => {
    try {
      const response = await fetchResultsSearch(raceID, eventName, searchTerm);

      const { results: list } = response;

      setResults(list);
      setSearched(true);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const handleSearchKeyDown = useCallback(
    code => {
      if (code === 13) {
        if (search) {
          resultsSearch(race.id, event.name, search);
        } else {
          resultsList(race.id, event.name, gender.name, category.name, 1);
        }
      }
    },
    [search, race, event, gender, category],
  );

  const handleSearchClick = useCallback(() => {
    if (search) {
      resultsSearch(race.id, event.name, search);
    } else {
      resultsList(race.id, event.name, gender.name, category.name, 1);
    }
  }, [search, race, event, gender, category]);

  const handleSearchCancel = useCallback(() => {
    setValue('search', '');
    resultsList(race.id, event.name, gender.name, category.name, 1);
  }, [race, event, gender, category]);

  useEffect(() => {
    const { race: selectedRace } = queryString.parse(location.search);

    eventsList(selectedRace).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [eventsList]);

  useEffect(() => {
    if (events.length) {
      setValue('event', events[0]);
    }
  }, [events]);

  useEffect(() => {
    if (event) {
      setValue('search', '');
      setValue('gender', filter[0]);

      categoriesList(race.id, event.name);
    }
  }, [event]);

  useEffect(() => {
    if (categories.length) {
      setValue('category', categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    if (gender) {
      if (page > 1) {
        setPage(1);
      } else if (race && event && gender && page) {
        resultsList(race.id, event.name, gender.name, category?.name, page);
      }
    }
  }, [gender]);

  useEffect(() => {
    if (category) {
      if (page > 1) {
        setPage(1);
      } else if (race && event && gender && page) {
        resultsList(race.id, event.name, gender.name, category?.name, page);
      }
    }
  }, [category]);

  useEffect(() => {
    if (race && event && gender && page) {
      resultsList(race.id, event.name, gender.name, category?.name, page);
    }
  }, [page]);

  return (
    <>
      <Helmet>
        <title>{race?.name ? `Results | ${race?.name}` : 'Loading...'}</title>
      </Helmet>
      <div className="min-height">
        <div className="banner">
          <p>{race?.name}</p>
          <p>{race?.date}</p>
        </div>
        <div className="container max-w-4xl mx-auto pt-6 px-6 md:px-8">
          <div className="flex flex-col-reverse sm:flex-row justify-evenly sm:justify-between">
            <div className="flex items-center justify-center sm:justify-start">
              {searched && (
                <button
                  type="button"
                  className="button button--text-only !px-0"
                  style={{ marginRight: '10px' }}
                  onClick={() => handleSearchCancel()}>
                  <XCircle size={20} weight="bold" />
                </button>
              )}
              <InputField
                name="search"
                control={control}
                placeholder="Search By Name or BIB"
                onKeyDown={event => handleSearchKeyDown(event.keyCode)}
              />
              <button type="button" className="button ml-2" onClick={() => handleSearchClick()}>
                <MagnifyingGlass size={18} weight="bold" />
              </button>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-end ml-4 mb-4 md:mb-0">
              <div className="mr-0 md:mr-3">
                <DropdownField name="event" control={control} options={events} loading={loading} />
              </div>
              <div className="mr-0 md:mr-3">
                <DropdownField name="gender" control={control} options={filter} loading={loading} />
              </div>
              {gender.name !== 'OVERALL' && categories.length && categories?.[0].id ? (
                <DropdownField
                  name="category"
                  control={control}
                  options={categories}
                  loading={loading}
                />
              ) : null}
              <button
                type="button"
                className="button ml-2 mt-2 md:mt-0"
                onClick={() => navigate(`/results/photos?raceID=${race.id}`)}>
                Photos
              </button>
            </div>
          </div>
        </div>
        <div className="container max-w-5xl mx-auto pb-6 px-6">
          {fetching && (
            <div className="mt-8">
              <Spinner size="medium" />
            </div>
          )}
          {!fetching &&
            (results.length > 0 ? (
              <>
                <div className="table-responsive bg-white shadow-xl rounded-md p-3 mt-8 mb-6 border border-gray-300 no-scrollbar">
                  <table className="table table-hover">
                    <thead>
                      <tr className="border border-t-0 border-r-0 border-l-0 border-b-2">
                        {header.map((item, index) => (
                          <th key={index} scope="col">
                            <span className="text-xs text-black font-semibold">{item.name}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="text-xs">
                      {results.map((result, index) => (
                        <tr
                          key={index}
                          className="transition-colors duration-500"
                          onClick={() =>
                            navigate(
                              `/results/details?raceID=${race.id}&event=${result.event}&bibNo=${result.bibNo}&name=${result.name}`,
                            )
                          }>
                          {Object.keys(result).map((item, index) => (
                            <td key={index}>{result[item]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!searched && null}
              </>
            ) : (
              <div className="table-responsive lg:px-20 py-8">
                <p className="mb-1 text-md">
                  <strong>No Results Found</strong>
                </p>
                {/* <p className="text-sm">
                  We couldn&apos;t find any results matching your criteria. Please try toggling the
                  event dropdown and check your selections once again. If you still can&apos;t find
                  what you&apos;re looking for, it&apos;s possible that the result is not available
                  at this time.
                </p> */}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ResultList;
