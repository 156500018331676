/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import parsePhoneNumberFromString from 'libphonenumber-js';
import * as yup from 'yup';

import {
  CHECKBOX_NO_ERROR,
  FIELD_REQUIRED,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
} from '../../constants/validation/errors';
import { DROPDOWN_FIELD, INPUT_FIELD } from '../../constants/form/types';

const emailValidation = yup
  .string()
  .email(INVALID_EMAIL)
  .required(FIELD_REQUIRED)
  .matches(
    /^(?!.*@(gmail|yahoo|rediff|hotmail|outlook)\.).*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
    'Only corporate email addresses are allowed',
  );

const phoneValidation = yup
  .string()
  .required(FIELD_REQUIRED)
  .test('phone-validate', INVALID_PHONE_NUMBER, value => {
    if (value && value.length > 1) {
      const number = parsePhoneNumberFromString(value, 'IN');
      return number && number.isValid();
    }
    return false;
  });

const generatePersonSchema = index => ({
  [`name-${index}`]: yup.string().required(FIELD_REQUIRED),
  [`email-${index}`]: emailValidation,
  [`phone-${index}`]: phoneValidation,
  [`gender-${index}`]: yup.string(),
  [`tShirt-${index}`]: yup.object().nullable().required(FIELD_REQUIRED),
});

const extractDomain = email => email?.split('@')[1];

export const YupSchema = yup
  .object()
  .shape({
    ...generatePersonSchema(1),
    ...generatePersonSchema(2),
    ...generatePersonSchema(3),
    ...generatePersonSchema(4),
    ...generatePersonSchema(5),
    event: yup.string(),
    companyName: yup.string().required(FIELD_REQUIRED),
    teamName: yup.string().required(FIELD_REQUIRED),
    teamCaptain: yup.object().nullable().required(FIELD_REQUIRED),
    waiver: yup.boolean().test('check-yes-validate', null, function (value) {
      if (value) {
        return true;
      }

      return this.createError({
        path: this.path,
        message: CHECKBOX_NO_ERROR,
      });
    }),
  })
  .test('same-domain', 'All emails must have the same domain', function (values) {
    const emails = [
      values['email-1'],
      values['email-2'],
      values['email-3'],
      values['email-4'],
      values['email-5'],
    ];
    const domains = emails.map(email => extractDomain(email)).filter(Boolean); // Filter out undefined
    if (domains.length === 0 || !domains.every(domain => domain === domains[0])) {
      return this.createError({
        path: 'sameDomain',
        message: 'All emails must have the same domain',
      });
    }
    return true;
  })
  .test('unique-phones', 'Phone numbers must be unique', function (values) {
    const phones = [
      values['phone-1'],
      values['phone-2'],
      values['phone-3'],
      values['phone-4'],
      values['phone-5'],
    ];
    const uniquePhones = new Set(phones);
    if (uniquePhones.size !== phones.length) {
      return this.createError({
        path: 'uniquePhones',
        message: 'Phone numbers must be unique',
      });
    }
    return true;
  })
  .test(
    'female-required',
    'At least one gender must be female when event name is "MIXED RELAY"',
    function (values) {
      if (values.event?.name === 'MIXED RELAY (5K) (₹0)') {
        const genders = [
          values['gender-1'],
          values['gender-2'],
          values['gender-3'],
          values['gender-4'],
          values['gender-5'],
        ];
        if (!genders.includes('Female')) {
          return this.createError({
            path: 'mixedGender',
            message: 'At least one gender must be female when event name is "MIXED RELAY"',
          });
        }
      }

      return true;
    },
  );

export const TeamTemplate = [
  {
    type: INPUT_FIELD,
    name: 'companyName',
    label: 'Company Name',
    required: true,
  },
  {
    type: INPUT_FIELD,
    name: 'teamName',
    label: 'Team Name',
    required: true,
  },
  {
    type: DROPDOWN_FIELD,
    name: 'teamCaptain',
    label: 'Team Captain',
    options: [],
    required: true,
  },
];
