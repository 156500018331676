import * as yup from 'yup';

import { INPUT_FIELD, PASSWORD_FIELD } from '../../../../constants/form/types';

import { FIELD_REQUIRED, INVALID_EMAIL } from '../../../../constants/validation/errors';

const Login = {
  YupSchema: yup.object().shape({
    email: yup.string().email(INVALID_EMAIL).required(FIELD_REQUIRED),
    password: yup.string().required(FIELD_REQUIRED),
  }),
  DefaultValues: {
    email: '',
    password: '',
  },
  Template: {
    fields: [
      {
        type: INPUT_FIELD,
        name: 'email',
        label: 'Email',
      },
      {
        type: PASSWORD_FIELD,
        name: 'password',
        label: 'Password',
      },
    ],
  },
};

export default Login;
