/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

import FormBuilder from '../../../../../helper/formBuilder';

import Schema from '../../../../../schema/form/profile/emergency';

import Button from '../../../../button';

function Emergency({ data: emergency, loading, onUpdateClick }) {
  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: Schema.DefaultValues,
    resolver: yupResolver(Schema.YupSchema),
  });

  useEffect(() => {
    if (emergency) {
      const { relationship } = emergency;

      setValue('name', emergency.name || '');
      setValue('phone', emergency.phone || '');
      setValue(
        'relationship',
        relationship
          ? {
              id: relationship.toLowerCase(),
              name: relationship,
            }
          : null,
      );
    }
  }, [emergency]);

  const onSubmit = useCallback(data => {
    onUpdateClick({
      ...data,
      relationship: data.relationship.name,
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-12 gap-5 row-gap-5 mb-4">
        {FormBuilder({
          template: Schema.Template,
          control,
        }).map(({ field }, index) => (
          <div key={index} className="col-span-12 sm:col-span-6">
            {field}
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <Button label="Update" fullWidth={isMobile} loading={loading} disabled={loading} />
      </div>
    </form>
  );
}

Emergency.defaultProps = {
  data: null,
  loading: false,
};

Emergency.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onUpdateClick: PropTypes.func.isRequired,
};

export default Emergency;
