/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { XCircle, MagnifyingGlass } from 'phosphor-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ProgressiveImage from 'react-progressive-graceful-image';
import queryString from 'query-string';

import InputField from '../../../components/fields/input';

import Spinner from '../../../components/loader/spinner';

import { fetchRaceDetails } from '../../../services/apis/business/race';
import { fetchParticipantSearch } from '../../../services/apis/business/participant';

function ResultPhotos() {
  const location = useLocation();

  const [details, setDetails] = useState(null);
  const [results, setResults] = useState([]);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const search = watch('search');

  const raceDetails = useCallback(async raceID => {
    const response = await fetchRaceDetails(raceID);

    setLoading(false);
    setDetails(response);
  }, []);

  useEffect(() => {
    const { raceID } = queryString.parse(location.search);

    raceDetails(raceID).catch(error => {
      setLoading(false);

      throw error;
    });
  }, [raceDetails]);

  const resultsSearch = useCallback(async (raceID, searchTerm) => {
    try {
      const response = await fetchParticipantSearch(raceID, searchTerm);

      const { results: list } = response;

      setResults(list);
      setSearched(true);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const handleSearchKeyDown = useCallback(
    code => {
      if (code === 13) {
        const { raceID } = queryString.parse(location.search);

        if (search) {
          resultsSearch(raceID, search);
        }
      }
    },
    [search],
  );

  const handleSearchClick = useCallback(() => {
    const { raceID } = queryString.parse(location.search);

    if (search) {
      resultsSearch(raceID, search);
    }
  }, [search]);

  const handleSearchCancel = useCallback(() => {
    setValue('search', '');
    setResults([]);
    setSearched(false);
  }, []);

  if (loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  return (
    <LightgalleryProvider>
      <Helmet>
        <title>{details?.raceName ? `Photos | ${details?.raceName}` : 'Loading...'}</title>
      </Helmet>
      <div className="min-height">
        <div className="banner">
          <p>Image Search</p>
          <p>{details?.raceName}</p>
        </div>
        <div className="container max-w-4xl mx-auto pt-6 px-6 md:px-8">
          <div className="flex items-center">
            {searched && (
              <button
                type="button"
                className="button button--text-only !px-0"
                style={{ marginRight: '10px' }}
                onClick={() => handleSearchCancel()}>
                <XCircle size={20} weight="bold" />
              </button>
            )}
            <InputField
              name="search"
              control={control}
              placeholder="Search By Name or BIB"
              onKeyDown={event => handleSearchKeyDown(event.keyCode)}
            />
            <button type="button" className="button ml-2" onClick={() => handleSearchClick()}>
              <MagnifyingGlass size={18} weight="bold" />
            </button>
          </div>
        </div>
        <div className="container max-w-4xl mx-auto pt-6 px-0 md:px-8 pb-6">
          {fetching && (
            <div className="mt-8">
              <Spinner size="medium" />
            </div>
          )}
          {searched &&
            (results.length > 0 ? (
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                <Masonry gutter="15px">
                  {results.map((item, index) => (
                    <LightgalleryItem key={index} group="race-capture" src={item}>
                      <ProgressiveImage delay={1000} src={item} placeholder={`${item}?tr=bl-10`}>
                        {src => <img src={src} alt={`${details.bibNo}'s logo`} />}
                      </ProgressiveImage>
                    </LightgalleryItem>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            ) : (
              <p className="text-center mt-2">No Images Found!</p>
            ))}
        </div>
      </div>
    </LightgalleryProvider>
  );
}

export default ResultPhotos;
