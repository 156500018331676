/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Quill } from 'react-quill';

function CustomUndo() {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
      <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
    </svg>
  );
}

function CustomRedo() {
  return (
    <svg viewBox="0 0 18 18">
      <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
      <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
    </svg>
  );
}

function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

export function QuillToolbar() {
  return (
    <div id="toolbar" className="rounded-t border-gray-300 !border-b-0">
      <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
          <option value="arial">Arial</option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
        </select>
        <select className="ql-size" defaultValue="medium">
          <option value="extra-small">Size 1</option>
          <option value="small">Size 2</option>
          <option value="medium">Size 3</option>
          <option value="large">Size 4</option>
        </select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button type="button" aria-label="Bold" className="ql-bold" />
        <button type="button" aria-label="Italic" className="ql-italic" />
        <button type="button" aria-label="Underline" className="ql-underline" />
        <button type="button" aria-label="Strike" className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button type="button" aria-label="List Ordered" className="ql-list" value="ordered" />
        <button type="button" aria-label="List Bullet" className="ql-list" value="bullet" />
        <button type="button" aria-label="Indent -1" className="ql-indent" value="-1" />
        <button type="button" aria-label="Indent +1" className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button type="button" aria-label="Script Super" className="ql-script" value="super" />
        <button type="button" aria-label="Script Sub" className="ql-script" value="sub" />
        <button type="button" aria-label="Blockqoute" className="ql-blockquote" />
        <button type="button" aria-label="Direction" className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select type="button" aria-label="Align" className="ql-align" />
        <select type="button" aria-label="Color" className="ql-color" />
        <select type="button" aria-label="Background" className="ql-background" />
      </span>
      <span className="ql-formats">
        <button type="button" aria-label="Formula" className="ql-formula" />
        <button type="button" aria-label="Code Block" className="ql-code-block" />
        <button type="button" aria-label="Clean" className="ql-clean" />
      </span>
      <span className="ql-formats">
        <button type="button" className="ql-undo">
          <CustomUndo />
        </button>
        <button type="button" className="ql-redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  );
}

export default QuillToolbar;
