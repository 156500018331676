import http from '../../axios';

export const createRegistration = async data => http.post('/registration/create', data);

export const createNovoHealthRegistration = async data =>
  http.post('/registration/create/novoHealthRelay', data);

export const fetchRegistrationDetails = async id =>
  http.get('/registration/details', {
    params: {
      id,
    },
  });

export const uploadDocument = async data =>
  http.post('/registration/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
