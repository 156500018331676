import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container max-w-4xl mx-auto">
      <div className="px-10 pt-10 pb-10">
        <h1 className="text-3xl font-semibold mb-8">Racetime Privacy Policy</h1>
        <h1 className="text-base md:text-lg mb-2">
          <strong>YOUR PRIVACY MATTERS TO US</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Our goal is to make sure you know how we collect, use, share and store your personal data
          when you use the Racetime India website and mobile app, the Racetime India Sports website
          and mobile app, and compatible Racetime India devices.
        </p>
        <h1 className="text-lg md:text-xl mt-6 mb-2">
          <strong>WHAT DO WE COLLECT</strong>
        </h1>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Account Information</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          We collect your name and email when you create a Racetime India account to make sure your
          Racetime India account is completely personalized. We also store information such as your
          sleep and wake times, so we don’t send you “get moving” notifications while you’re
          sleeping. You probably want us to remember that.
        </p>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Device Information</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          When you add a Racetime India device to your Racetime India account, we ask for
          information such as your height, weight, gender and birthdate so we can personalize your
          stats and goals. We also need to hang on to your recorded activities and heart rate, so
          you can tell when you beat that PR or lower your fitness age.
        </p>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Device Sync Data</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          When you sync your device, we collect data such as your IP address, sync time and date and
          battery level to help identify and resolve syncing issues and to provide better customer
          support. We also collect usage and trend data to help us improve our features and apps.
        </p>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Contact Information</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          We know you want to stay off the grid, but we may collect things such as your name, email
          address and phone number during support calls to ensure you get the help you need. If
          you’re added as an emergency contact, Racetime India stores the contact information
          required to fulfill those services.
        </p>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Location-based Services</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Services such as GPS tracking during a run or weather updates on your device require that
          Racetime India collect your location at that time. Your GPS data during activities does
          get uploaded to Racetime India or Racetime India Sports, but you have control over who
          sees it from there.
        </p>
        <h1 className="text-base md:text-lg mt-3 mb-2">
          <strong>Cookies</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-2">
          We use cookies and other technologies on our websites and apps to help us improve customer
          experiences.
        </p>
        <p className="text-sm md:text-base font-medium mb-2">
          Your privacy is important to Racetime India. We developed this Privacy Policy to provide
          you with information on how we process your personal data when you use the Racetime India
          website, the Racetime India Mobile app, the Racetime India Sports website and app, and
          Racetime India wearable devices or other Racetime India devices that are compatible with
          Racetime India.
        </p>
        <p className="text-sm md:text-base font-medium mb-2">
          Other Racetime India websites, apps and products that do not include a link to this
          Privacy Policy and include a link to a different Racetime India privacy policy are
          governed by that privacy policy.
        </p>
        <p className="text-sm md:text-base font-medium mb-2">
          “Personal data” is information relating to an identified or identifiable natural person.
        </p>
        <p className="text-sm md:text-base font-medium mb-2">
          To jump to a specific section of this Privacy Policy, please click on a link below:
        </p>
        <p className="text-sm md:text-base font-medium">
          Categories of Personal Data Processed by Racetime India
          <br />
          Categories of Recipients of Personal Data
          <br />
          Transfers of Personal Data
          <br />
          Cookies and Similar Technologies
          <br />
          Children
          <br />
          Privacy Policy Updates
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">
          Categories of Personal Data Processed by Racetime India
        </h1>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Personal data that is processed when you create a Racetime India account:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          When you create a Racetime India account, we ask you to provide your email address and
          name. You can choose to provide only your first name or a nickname instead of your full
          name if you wish.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUNDS:</strong>
        </h1>
        <ol className="text-sm md:text-base font-medium list-decimal list-outside mt-2">
          <li style={{ marginBottom: '8px' }}>
            Racetime India processes your email address because you use your email address and your
            password to sign in to your account. The legal ground for processing your email address
            for this purpose is based on Racetime India’s legitimate interest in protecting the
            security of your account.
          </li>
          <li style={{ marginBottom: '8px' }}>
            Racetime India also processes your email address for the purpose of sending you
            important information about your Racetime India products, apps or account, such as
            important safety information or material changes to this Privacy Policy. The name you
            provide is associated with your account profile, and is displayed to your Connections
            when you engage in Challenges, Leaderboards, Groups and Segments, and when you submit
            comments. The legal ground for processing your email address and name for these purposes
            is Racetime India’s legitimate interest in providing you important safety or other
            information about your Racetime India products, apps or account or material changes to
            this Privacy Policy, and in providing you an opportunity to engage with other Racetime
            India users.
          </li>
          <li style={{ marginBottom: '8px' }}>
            If you provide your opt-in consent to receiving marketing information from Racetime
            India, Racetime India will also process your email address for the purpose of sending
            you marketing information about Racetime India products and apps. The legal ground for
            processing your email address for this purpose is your consent. You may withdraw your
            consent at any time by changing your preferences in your Racetime India account or
            through the unsubscribe link at the bottom of our marketing emails. The marketing emails
            you receive from Racetime India are based on the preferences you provide in your
            Racetime India account, the locale indicated by your Internet Protocol (IP) address, the
            types of Racetime India devices you have added to your Racetime India account, and any
            subscriptions included in your Racetime India account. The legal ground for processing
            this data for this purpose is Racetime India’s legitimate interest in reducing the
            number of marketing emails sent to each particular customer by selecting which customers
            receive a particular marketing email rather than sending every marketing email to every
            customer who has consented to receiving marketing emails.
          </li>
          <li style={{ marginBottom: '8px' }}>
            If you provide your opt-in consent, Racetime India will process your email address for
            the purpose of sending you notifications (e.g., when you join a Challenge, when a
            Challenge is over, when comments to a Challenge are submitted, etc.). The legal ground
            for processing your email address for this purpose is your consent. You can withdraw
            this consent at any time by opting-out of receiving such notifications.
          </li>
          <li>
            Racetime India also processes your email address to associate it with your Racetime
            India account when you interact with our customer support representatives. The legal
            ground for this processing is our legitimate interest in providing quality customer
            support.
          </li>
        </ol>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Additional personal data that is processed if you choose to provide it when you create a
            Racetime India account:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          You can add additional information to your profile, such as your location, types of
          activities you participate in (e.g., running, hiking, cycling, golf, etc.), and your
          gender, birthdate, height and weight.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          This information is used to calculate the calories you burn during an activity and to
          enable you to show your Connections the types of activities you participate in if you
          wish. The legal ground for processing this information for these purposes is your consent.
          You can withdraw your consent by deleting this information from your account profile.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you choose to upload your activities, location and
            activity data to Racetime India:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          You can choose to upload activities (e.g., runs, walks, bike rides, swims, hikes, gym
          activities, etc.) and activity data (e.g., steps, distance, pace, activity time, calories
          burned, heart rate, sleep, location, golf stats, menstrual cycle information, hydration,
          music played, etc.) from your device to your Racetime India account. You can use your
          device without providing your consent to upload your activities to Racetime India. If you
          choose to upload your activities to Racetime India, you control whether others can see
          your activity data by managing the privacy settings in your Racetime India account. Your
          activity data is set to “Private” by default.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <ol className="text-sm md:text-base font-medium list-decimal list-outside mt-2">
          <li style={{ marginBottom: '8px' }}>
            Racetime India processes your activity data, if you choose to upload it to Racetime
            India, to enable you to analyze your activity data, see your location on your activity
            course and segment maps, see your heart rate related metrics such as stress score, Body
            Battery or pulse oximetry, track your fitness goals, and, if you wish, share your
            activity data with others. If you reside in the European Economic Area, U.K. or in
            Switzerland, the legal ground for this processing is your explicit consent, which you
            can withdraw at any time within your Racetime India account.
          </li>
          <li style={{ marginBottom: '8px' }}>
            If you choose to upload activity data (such as steps, distance, pace, activity time,
            calories burned, heart rate, sleep, menstrual cycle information, hydration, music
            played, etc.) from your Racetime India device to your Racetime India account and you
            choose to participate in Insights, then you will be presented with an Insights section
            in your Racetime India account in which you will be provided with recommendations and
            motivational messages, information and links to articles that may be of interest to you
            based upon your activity data, and a comparison of your activity data with aggregated
            activity data of others in the Racetime India community. If you reside in the European
            Economic Area, U.K. or in Switzerland, the legal ground for processing this data for
            this purpose is your explicit consent, which you can withdraw at any time within your
            Racetime India account.
          </li>
          <li style={{ marginBottom: '8px' }}>
            Racetime India also processes your activity data, if you choose to upload it to Racetime
            India, in an aggregated manner to analyze usage and trends and develop or improve
            features and services. The legal basis for this processing is Racetime India’s
            legitimate interest in providing relevant and quality features and services.
          </li>
          <li>
            If you choose to upload your activity data to Racetime India and you are opted in to
            product improvement, Racetime India will process your activity data uploaded to your
            account for research and development purposes internally to help us build better and
            more relevant Racetime India products and services. If you reside in the European
            Economic Area, U.K., or Switzerland, the legal ground for processing this data for this
            purpose is your explicit consent, which you can withdraw at any time within your
            Racetime India account.
          </li>
        </ol>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you add a device to your Racetime India account:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          When you add certain devices to your Racetime India account we ask for additional
          information, such as your gender, height, weight, birthdate, activity level (low, medium,
          or high) and normal bed and wake times.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          This information is processed to calculate the calories you burn during an activity,
          provide you Insights about your activities and sleep (if you give your explicit consent to
          be presented with Insights), and to set your wearable device to “do not disturb” mode
          during your normal sleep hours. The legal ground for processing this information for this
          purpose is your consent. You can withdraw your consent at any time by removing your device
          from your Racetime India account and deleting this information from your profile.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you choose to participate in competitions or
            training activities through the Racetime India Sports website or mobile app:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          You can choose to participate in competitions or training activities (e.g., training
          camps, courses, or plans) through Racetime India Sports, and you can upload these
          activities (e.g., runs, walks, bike rides, swims, hikes, gym activities, etc.) and
          activity data (e.g., total time, distance, average pace, average heart rate, pace zone,
          heart rate zone, etc.) from your device to your Racetime India account. The training- or
          competition-related information will be synced to your Racetime India Sports account. You
          can also use your device without providing your consent to participate in competitions or
          training activities through the Racetime India Sports website or app.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          Racetime India processes your activity and training data, if you choose to upload it to
          Racetime India, to enable you to analyze your activity data, see your training summary of
          courses, including your achievement ratio, personal, and class performance comparison,
          class points, competition results such as cumulative points of speed or pace, fat-burning
          statistics, achievements, distances, elevation, etc. The legal ground for processing this
          information for these purposes is your consent. You can withdraw your consent at any time
          by deleting the activity and training data from your account.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Personal data that is processed when you sync your device:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          When you sync your device through Racetime India, Racetime India Mobile or Racetime India
          Express, we log data about the transmission, such as the IP address used when syncing, the
          sync time and date, crash/diagnostic logs, geographic location of the device, information
          about your device, information about the network used to sync (e.g., Wi-Fi or cellular)
          and device battery level. For compatible products, we will also gather analytics on how
          you configure and use certain features
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          We process this information to help identify and resolve errors or syncing issues. The
          legal ground for processing this information for this purpose is Racetime India’s
          legitimate interest in resolving errors or syncing issues and providing quality product
          support. We also process this information to analyze usage and trends and develop or
          improve features and services. The legal basis for this processing is Racetime India’s
          legitimate interest in providing relevant and quality features and services.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you connect your Racetime India account to a
            third-party app if you choose:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          If you choose to enable your Racetime India account to access accounts you have with other
          app providers, such as your MyFitnessPal, Strava or TrainingPeaks account, we will obtain
          information about you from such account, such as the number of calories consumed in a
          particular day based on information from your MyFitnessPal account or courses and segments
          from your Strava account.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          This information is used to supplement your activity information in Racetime India with
          information you have provided to such third-party apps. The legal ground for this
          processing is your consent. You can withdraw your consent at any time by disconnecting
          your Racetime India account from the third-party app within your Racetime India account.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Personal data that is processed when you communicate with Racetime India:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          When you interact with our customer support representatives via email, telephone, online
          or in person, we collect personal data, such as your name, mailing address, phone number,
          email address and contact preferences; and information about the Racetime India products
          you own, such as their serial numbers and dates of purchase. We also may create event logs
          that are useful in diagnosing product or app performance-related issues, and capture
          information relating to the support or service issue. To improve customer service, subject
          to applicable laws, we may also record and review conversations with customer support
          representatives, and analyze any feedback provided to us through voluntary customer
          surveys. With your consent, our customer support representatives may sign in to your
          Racetime India account, if appropriate, to help troubleshoot and resolve your issue.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          We use this information to provide you with customer and product support and to monitor
          the quality and types of customer and product support we provide to our customers. The
          legal ground for processing this information for these purposes is Racetime India’s
          legitimate interests in providing quality product support. The legal ground for signing in
          to your Racetime India account, if appropriate, to help troubleshoot and resolve your
          issue is consent, which you may withdraw.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you use location-based services on your Racetime
            India device or app:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          If you elect to use location-based services, such as weather, on your Racetime India
          device or app, then the physical location of your device will be collected in order for
          Racetime India or our providers to provide you with such location-based services.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          The purpose of processing the location of your device is to provide you the location-based
          services you wish to use. The legal ground for processing this data for this purpose is
          performance of a contract.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Personal data that is processed when you are an emergency contact on someone&apos;s
            Racetime India account:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          If a Racetime India user adds you as an emergency contact to their Racetime India account,
          they provide us your phone number or email address.
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>PURPOSES AND LEGAL GROUND:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          We use this information to notify you of an incident involving the Racetime India user.
          The legal ground for processing this information for these purposes is our legitimate
          interest in enabling our users to notify their emergency contacts if they are involved in
          an incident and need assistance.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">
          Categories of Recipients of Personal Data
        </h1>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Other Racetime India users:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          Your activities and activity data associated with your Racetime India account are set to
          “Private” by default. You may decide to allow others to view your activities and activity
          data by changing the privacy settings in your Racetime India account. You can join Groups
          and make Connections with other Racetime India users. You may also choose to join other
          Racetime India users in Challenges and Leaderboards. When you interact with others in
          these ways, you will be displaying your data relating to the Challenge or Leaderboard
          (e.g., aggregate number of steps during the duration of a steps Leaderboard or Challenge)
          even if your privacy settings in your Racetime India account are set to “Private.”
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Other Racetime India Sports users:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          You may choose to join training activities or participate in competitions with other
          Racetime India Sports users through the Racetime India Sports website or app. When you
          interact with other Racetime India Sports users in these ways, you will be displaying your
          data relating to your training summary or competition results through Racetime India
          Sports.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Family members of Daily, Monthly or Weekly Challenge participants:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          When you choose to participate in Challenges, Challenge participants and anyone in their
          family account may view your data relating to the Challenge and its Leaderboard, even if
          they are not participating in the Challenge and even if your privacy settings in your
          Racetime India account are set to “Private.”
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Your Tracking invitees:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          Tracking is a feature available on some Racetime India devices/website that enables you to
          send a link to people of your choice that allows them to see the real-time location of
          your device. Because anyone with access to the link will be able to see the real-time
          location of your Racetime India device, you should use caution in determining to whom you
          want to send the link and be sure that you trust them to not send the link to others whom
          you do not want to be able to view the location of your Racetime India device.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Your emergency contacts:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          If you provide emergency contacts to Racetime India to be used in the event that an
          accident is detected or reported and you are involved in an incident that is detected by
          your Racetime India device, we will send a notification with information about the
          incident, such as location, to your chosen emergency contacts via SMS or email.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Content or feature providers; other third parties:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          From time to time, we share or sell activity data in a de-identified and aggregated manner
          with or to companies that provide Racetime India and our customers with content or
          features for the purpose of enhancing the quality of the content or features they provide,
          and with or to other third parties for research or other purposes.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>
            Third-party app, platform or service providers with whom you ask Racetime India to share
            your data:
          </strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          If you choose to authorize Racetime India to permit a third party, such as your wellness
          program provider, or an app, such as MyFitnessPal, Strava, or TrainingPeaks, to access
          your activity data in your Racetime India account, then we will share such data with the
          third party. We will not do this without your explicit consent. Once you direct us to
          share data with a third party, the third party’s handling of your personal data is the
          responsibility of that third party and you should carefully review the third party’s
          privacy policy. You can choose to stop sharing data with the third-party app, platform or
          service provider at any time within your Racetime India account.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Other service providers:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal">
          Racetime India uses cloud services from third parties such as Adobe and Emarsys to assist
          in sending emails. Those services track the activities associated with these emails, such
          as whether they were opened, whether links in the emails were clicked on, and whether
          purchases were made following clicks on those links. Racetime India uses this data to
          analyze the level of engagement with its emails. Racetime India uses third-party service
          providers to help us better understand usage of Racetime India Express and the Racetime
          India website and app, and for related purposes.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Other disclosures:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mb-2">
          We may disclose personal data about you to others: (a) if we have your valid consent to do
          so; (b) to comply with a valid subpoena, legal order, court order, legal process, or other
          legal obligation; (c) to enforce any of our terms and conditions or policies; or (d) as
          necessary to pursue available legal remedies or defend legal claims.
        </p>
        <p className="text-base md:text-lg font-normal">
          We may also transfer your personal data to an affiliate, a subsidiary or a third party in
          the event of any reorganization, merger, sale, joint venture, assignment, transfer or
          other disposition of all or any portion of Racetime India’s business, assets or stock,
          including, without limitation, in connection with any bankruptcy or similar proceeding,
          provided that any such entity that we transfer personal data to will not be permitted to
          process your personal data other than as described in this Privacy Policy without
          providing you notice and, if required by applicable laws, obtaining your consent.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-6">
          Transfers of Personal Data
        </h1>
        <p className="text-base md:text-lg font-normal mb-3">
          Racetime India is a global business. To offer our products, apps and services, we may need
          to transfer your personal data to other Racetime India companies in other countries. When
          you create a Racetime India account, add personal data in your account profile, or upload
          data to your Racetime India account, your personal data will be collected and stored on
          Racetime India servers in the India
        </p>
        <p className="text-base md:text-lg font-normal">
          All Racetime India companies are required to follow the privacy practices set forth in
          this Privacy Statement.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">
          Cookies and Similar Technologies
        </h1>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Website:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mb-2">
          To help analyze how you and other visitors navigate the Racetime India website, compile
          aggregate statistics about site usage and response rates, help diagnose any problems with
          Racetime India’s servers and administer the Racetime India website, we, with assistance
          from third-party analytics service providers, collect certain information when you visit
          our site. This information includes IP address, geographic location of the device, browser
          type, browser language, date and time of your request, time(s) of your visit(s), page
          views and page elements (e.g., links) that you click. We may use cookies, pixel tags, web
          beacons, clear GIFs or other similar tools on our site or in our email messages to assist
          us in collecting and analyzing such information. We use this information to provide
          better, more relevant content on our site, to identify and fix problems, and to improve
          your overall experience on our site.
        </p>
        <p className="text-base md:text-lg font-normal mb-2">
          If you do not want information collected through the use of these technologies, there is a
          simple procedure in most browsers that allows you to automatically decline many of these
          technologies, or to be given the choice of declining or accepting them.
        </p>
        <p className="text-base md:text-lg font-normal">
          If you reside in the European Union or other jurisdiction that requires us to obtain your
          consent to use cookies on our sites, then you will have an opportunity to manage your
          cookie preferences on the sites; except that certain cookies are required to enable core
          site functionality, and you cannot choose to disable those cookies.
        </p>
        <h1 className="text-xl md:text-2xl italic mt-4 mb-2">
          <strong>Racetime India Mobile app:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mb-2">
          We also collect data from users about their usage of Racetime India Mobile. The types of
          analytical information that are collected include the date and time the app accesses our
          servers, app version, the location of the device, language setting, what information and
          files have been downloaded to the app, user behavior (e.g., features used, frequency of
          use), device state information, device model, hardware and operating system information,
          and information relating to how the app functions. Racetime India uses this data to
          improve the quality and functionality of the Racetime India Mobile app, and other Racetime
          India apps; to develop and market products and features that best serve you and other
          users; and to help identify and fix app stability issues and other usability problems as
          quickly as possible.
        </p>
        <p className="text-base md:text-lg font-normal mb-2">
          The legal ground for processing this analytical information is our legitimate interest in
          understanding how our customers interact with our products, apps and websites so we can
          enhance the user experience and functionality of our products, apps and websites.
        </p>
        <p className="text-base md:text-lg font-normal">
          Here are examples of third-party providers of analytics and similar services we currently
          use:
        </p>
        <h1 className="text-lg md:text-xl mt-4 mb-2">
          <strong>ANALYTICS SERVICES:</strong>
        </h1>
        <ol className="text-sm md:text-base font-medium list-disc list-outside mt-4">
          <li style={{ marginBottom: '8px' }}>
            <strong>Google:</strong> Google Analytics is used to track site statistics and user
            demographics, interests and behavior on websites. We also use Google Search Console to
            help understand how our website visitors find our website and to improve our search
            engine optimization. Find out more information about how this analytics information may
            be used, how to control the use of your information, and how to opt-out of having your
            data used by Google Analytics
          </li>
          <li>
            <strong>Azure Application Insights:</strong> Azure Application Insights is used to help
            us better understand usage to improve user experience. Azure Application Insights is a
            Microsoft service.
          </li>
        </ol>
        <h1 className="text-lg md:text-xl mt-5 mb-2">
          <strong>APPLICATION PERFORMANCE MONITORING:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mt-1">
          Racetime India uses third party applications to analyze and monitor the performance of our
          complex ecosystem. To provide such services, these apps receives basic request
          information, including IP address, necessary to detect and diagnose anomalous errors and
          response times.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">Children</h1>
        <p className="text-base md:text-lg font-normal mt-4">
          We request individuals under the age of 13 in the U.S. and under the age of 16 in the rest
          of the world not provide personal data to Racetime India. If we learn that we have
          collected personal data from a child under the age of 13 in the U.S. or under 16 in the
          rest of the world, we will take steps to delete the information as soon as possible.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">Privacy Policy Updates</h1>
        <p className="text-base md:text-lg font-normal mt-4">
          We may update this Privacy Policy from time to time as we add new products and apps, as we
          improve our current offerings and as technologies and laws change. You can determine when
          this Privacy Policy was last revised by referring to the “Last updated” legend at the top
          of this page. Any changes will become effective upon our posting of the revised Privacy
          Policy.
        </p>
        <p className="text-base md:text-lg font-normal mt-2">
          We will provide notice to you if these changes are material and, where required by
          applicable law, we will obtain your consent. This notice will be provided by email or by
          posting notice of the changes on the Racetime India websites and apps that link to this
          Privacy Policy.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">
          Retention of Personal Data
        </h1>
        <p className="text-base md:text-lg font-normal mt-4">
          We will retain your personal data as long as your Racetime India account is considered to
          be active. In addition, see below under “Your Rights” for a description of your right of
          erasure.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">
          Data Controller and Data Protection Officer
        </h1>
        <p className="text-base md:text-lg font-normal mt-4">
          If you reside in a country outside India the EEA, U.K. or Switzerland, then your personal
          data collected by Racetime India is controlled by Racetime India.
        </p>
        <h1 className="text-3xl md:text-4xl font-extrabold mt-6 mb-2">Your Rights</h1>
        <h1 className="text-lg md:text-xl mt-5 mb-2">
          <strong>RESIDENTS OF THE U.S.:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mt-3">
          If you reside in the U.S., as a parent or legal guardian, you have the right under the
          Children’s Online Privacy Protection (COPPA) Rule to review or delete the information
          Racetime India collects from your child. You may also refuse to permit further collection
          or use of the information. To do so, please contact us by emailing at team@racetime.in
        </p>
        <p className="text-base md:text-lg font-normal mt-2">
          <strong>Further Information</strong> If you have any specific questions, please email us
          at: team@racetime.in
        </p>
        <h1 className="text-lg md:text-xl mt-5 mb-2">
          <strong>NOTICE FOR CALIFORNIA RESIDENTS OF PRIVACY PRACTICES AND RIGHTS</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mt-3">
          If you are a California resident, California law may provide you with additional rights
          regarding your personal data.
        </p>
        <h1 className="text-lg md:text-xl mt-5 mb-2">
          <strong>RESIDENTS OF THE EU OR U.K.:</strong>
        </h1>
        <p className="text-base md:text-lg font-normal mt-3">
          If you reside in the European Union or U.K., you have the right, subject to the conditions
          set out in the General Data Protection Regulation (GDPR) or U.K. Data Protection Act 2018,
          to request from Racetime India access to and rectification or erasure of your personal
          data, data portability, restriction of processing of your personal data, the right to
          object to processing of your personal data, and the right to lodge a complaint with a
          supervisory authority. For more information about these rights, please visit the European
          Commission’s “My Rights” page relating to GDPR, which can be displayed in a number of
          languages. If you reside outside of the European Union and U.K., you may have similar
          rights under your local laws.
        </p>
        <p className="text-base md:text-lg font-normal mt-3">
          To request access to or rectification, portability or erasure of your personal data, or to
          delete your Racetime India account, visit your profile center
        </p>
        <p className="text-base md:text-lg font-normal mt-3">
          If you live in the European Union or U.K. and you wish to exercise your right to
          restriction of processing or your right to object to processing, contact by email
          team@racetime.in
        </p>
        <p className="text-sm md:text-base italic font-normal mt-3">
          All trademarks are the property of their respective owners.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
