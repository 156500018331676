/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
export const dataURLtoFile = (dataUrl, filename) => {
  const array = dataUrl.split(',');
  const mime = array[0].match(/:(.*?);/)[1];
  const blobString = atob(array[1]);
  let { length } = blobString;
  const uInt8Array = new Uint8Array(length);

  while (length--) {
    uInt8Array[length] = blobString.charCodeAt(length);
  }

  return new File([uInt8Array], filename, { type: mime });
};

export const cleanObject = object => {
  Object.keys(object).forEach(key => {
    if (object[key] === '' || object[key] === null || object[key] === undefined) {
      object[key] = null;
    }
  });

  return object;
};
