import http from '../../axios';

export const registerUser = async data => http.post('/user/register', data);

export const confirmRegistration = async data => http.post('/user/confirm', data);

export const resendConfirmation = async data => http.post('/user/confirmation/resend', data);

export const fetchProfile = async () => http.get('/user/profile');

export const updateAccount = async data => http.put('/user/account', data);

export const updateEmergency = async data => http.put('/user/emergency', data);

export const uploadProfileImage = async data =>
  http.post('/user/profileImage', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
