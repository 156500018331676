import React from 'react';

function AboutUs() {
  return (
    <div className="container max-w-4xl mx-auto">
      <div className="px-10 pt-10 pb-10">
        <h1 className="text-3xl text-center font-semibold mb-8">About Us</h1>
        <p className="text-base font-medium">
          Racetime India is a premier race timing company based in Bangalore, India. We provide
          disposable chip timing services/re-usable triathlon chips for a variety of different
          sporting events ranging from 1-mile to 100 miles and everywhere in between.
        </p>
        <p className="text-base font-medium mt-3">
          Racetime India will be with you every step of the way to ensure your next event is a
          success. Our event management experience, can help you plan your event, set up online
          registration and score all participants at the finish line.
        </p>
        <p className="text-base font-medium mt-3">
          Looking for more information? Need a quote? We’d love to hear from you. Let us know what
          your race needs are and we will get back to you as soon as possible.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
