import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RouteV6Adapte = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      push: ({ search, state }) => navigate({ search }, { state }),
      replace: ({ search, state }) => navigate({ search }, { replace: true, state }),
    }),
    [navigate],
  );

  return children({ history: adaptedHistory, location });
};

export default RouteV6Adapte;
