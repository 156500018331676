import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryParamProvider } from 'use-query-params';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import store from './redux/store';

import RouteAdapter from './helper/routeAdapter';

import App from './container/app';

import './styles/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <HelmetProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </HelmetProvider>
    </QueryParamProvider>
  </BrowserRouter>,
);
