import React from 'react';

function NotFoundError() {
  return (
    <div className="container max-w-4xl min-height mx-auto flex items-center">
      <div className="flex flex-col w-full">
        <p className="text-6xl text-center font-bold text--primary">404</p>
        <p className="text-2xl text-center font-medium text--primary">Page Not Found</p>
      </div>
    </div>
  );
}

export default NotFoundError;
