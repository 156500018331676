/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function RadioGroupField({ control, name, label, tooltip, options, column, disabled, required }) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="radio-group-field">
      <label htmlFor={name} className="text-sm font-bold text-gray-800">
        {label}{' '}
        {required && (
          <span className="text-red-600" style={{ marginLeft: '2px' }}>
            *
          </span>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              style={{ marginLeft: '2px' }}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Tooltip>
        )}
      </label>
      <div
        className={classNames(
          'flex mt-2',
          column ? 'flex-col items-start' : 'items-center justify-evenly',
        )}>
        {options.map(
          (option, index) =>
            !option.hideInRegistration && (
              <div key={index}>
                <input
                  id={`${option.id}-${name}`}
                  type="radio"
                  name={name}
                  className="hidden"
                  value={option.name}
                  checked={field.value === option.name}
                  onChange={field.onChange}
                  disabled={disabled}
                />
                <label
                  htmlFor={`${option.id}-${name}`}
                  className="flex items-center cursor-pointer text-sm font-medium">
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border-2 border-gray-300" />
                  {option.name}
                </label>
              </div>
            ),
        )}
      </div>
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold mt-2">
          {fieldState.error?.message}
        </p>
      )}
    </div>
  );
}

RadioGroupField.defaultProps = {
  tooltip: null,
  column: false,
  disabled: false,
  required: false,
};

RadioGroupField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  column: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default RadioGroupField;
