/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { kebabCase, lowerCase } from 'lodash';
import { VelocityComponent } from 'velocity-react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import ProgressiveImage from 'react-progressive-graceful-image';
import SVG from 'react-inlinesvg';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-initials-sprites';
import PropTypes from 'prop-types';

import { logoutUser } from '../../../redux/saga/cognito/actions';

import Links from '../../../data/components/navigation/profile/links.json';

function Profile({ name, profileImage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [svg, setSVG] = useState(null);

  useEffect(() => {
    const avatars = new Avatars(sprites, {});

    setSVG(avatars.create(kebabCase(lowerCase(name))));
  }, []);

  const onSelect = useCallback(index => {
    if (index === Links.length - 1) {
      dispatch(logoutUser());
    } else {
      navigate(Links[index].link);
    }
  });

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className="relative">
        <button
          type="button"
          className="relative flex items-center"
          onClick={() => setVisible(!visible)}>
          {profileImage ? (
            <ProgressiveImage
              delay={1000}
              src={profileImage}
              placeholder={`${profileImage}?tr=w-100,bl-10`}>
              {src => (
                <img src={src} alt="user profile" className="w-8 h-8 object-cover rounded-full" />
              )}
            </ProgressiveImage>
          ) : (
            <SVG src={svg} className="w-8 h-8 shadow-md rounded-full" />
          )}
          <svg
            className="w-5 h-5 stroke-current text-black"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <VelocityComponent
          animation={{
            opacity: visible ? 1 : 0,
          }}
          duration={200}>
          <div
            className={classNames(
              'absolute right-0 z-20 mt-2 w-48 bg-white shadow-xl rounded-md border border-gray-300',
              visible ? 'block' : 'hidden',
            )}>
            <div className="text-black text-base font-semibold px-4 pt-2 mb-2">
              <p>Hello, {name.split(' ')[0]}</p>
            </div>
            <ul>
              {Links.map(
                (item, index) =>
                  item.active && (
                    <li
                      key={index}
                      className="px-4 py-2 text-left hover:bg-gray-100 transition-colors duration-700">
                      <button
                        type="button"
                        className="w-full flex items-center"
                        onClick={() => {
                          onSelect(index);
                          setVisible(!visible);
                        }}>
                        <span className="ml-0 text-sm">{item.label}</span>
                      </button>
                    </li>
                  ),
              )}
            </ul>
          </div>
        </VelocityComponent>
      </div>
    </OutsideClickHandler>
  );
}

Profile.defaultProps = {
  profileImage: null,
};

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
};

export default Profile;
