import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logged: false,
  refreshToken: null,
  accessToken: null,
  idToken: null,
  user: {},
  loading: true,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    sessionActive: (state, { payload }) => {
      const { refreshToken, accessToken, idToken, user } = payload;

      return {
        ...state,
        logged: true,
        refreshToken,
        accessToken,
        idToken,
        user,
        loading: false,
      };
    },
    sessionInactive: state => ({
      ...state,
      ...initialState,
      loading: false,
    }),
  },
});

export const { sessionActive, sessionInactive } = sessionSlice.actions;
export default sessionSlice.reducer;
