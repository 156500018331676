/* eslint-disable func-names */
import { differenceInYears, isValid, parse } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import * as yup from 'yup';

import {
  CHECKBOX_NO_ERROR,
  FIELD_REQUIRED,
  INVALID_DATE,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  MAXIMUM_AGE_ERROR,
  MINIMUM_AGE_ERROR,
} from '../../constants/validation/errors';

const YupSchema = yup.object().shape({
  firstName: yup.string().required(FIELD_REQUIRED),
  lastName: yup.string().required(FIELD_REQUIRED),
  email: yup.string().email(INVALID_EMAIL),
  phone: yup
    .string()
    .required(FIELD_REQUIRED)
    .test('phone-validate', null, function (value) {
      if (value && value.length > 1) {
        const number = parsePhoneNumberFromString(value, 'IN').formatInternational() || '';

        if (parsePhoneNumberFromString(number).isValid()) {
          return true;
        }

        return this.createError({
          path: this.path,
          message: INVALID_PHONE_NUMBER,
        });
      }

      return false;
    }),
  dob: yup
    .string()
    .required(FIELD_REQUIRED)
    .test('date-validate', null, function (value) {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());

      if (isValid(parsedDate)) {
        const years = differenceInYears(new Date(), parsedDate) || 0;

        if (years < Number(process.env.REACT_APP_MINIMUM_AGE)) {
          return this.createError({
            path: this.path,
            message: `${MINIMUM_AGE_ERROR} ${Number(process.env.REACT_APP_MINIMUM_AGE)}`,
          });
        }

        if (years > Number(process.env.REACT_APP_MAXIMUM_AGE)) {
          return this.createError({
            path: this.path,
            message: `${MAXIMUM_AGE_ERROR} ${Number(process.env.REACT_APP_MAXIMUM_AGE)}`,
          });
        }

        return true;
      }

      return this.createError({
        path: this.path,
        message: INVALID_DATE,
      });
    }),
  gender: yup.string(),
  bloodGroup: yup.object().nullable().required(FIELD_REQUIRED),
  tShirt: yup.object().nullable().required(FIELD_REQUIRED),
  event: yup.object().nullable().required(FIELD_REQUIRED),
  emergencyName: yup.string().required(FIELD_REQUIRED),
  emergencyPhone: yup
    .string()
    .required(FIELD_REQUIRED)
    .test('phone-validate', null, function (value) {
      if (value && value.length > 1) {
        const number = parsePhoneNumberFromString(value, 'IN').formatInternational() || '';

        if (parsePhoneNumberFromString(number).isValid()) {
          return true;
        }

        return this.createError({
          path: this.path,
          message: INVALID_PHONE_NUMBER,
        });
      }

      return false;
    }),
  emergencyRelationship: yup.object().nullable().required(FIELD_REQUIRED),
  waiver: yup.boolean().test('check-yes-validate', null, function (value) {
    if (value) {
      return true;
    }

    return this.createError({
      path: this.path,
      message: CHECKBOX_NO_ERROR,
    });
  }),
});

export default YupSchema;
