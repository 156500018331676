/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormBuilder from '../../../../../helper/formBuilder';

function Runner(props) {
  const { formId, participant, control, event, setValue, removeUserForm } = props;

  const [localEvent, setLocalEvent] = useState(null);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    let template = [...participant.template];
    template = template.map(item => ({
      ...item,
      name: `${item.name}-${formId}`,
    }));
    setFields(template);
  }, []);

  useEffect(() => {
    if (!event) {
      return;
    }

    if (localEvent === event) {
      return;
    }

    const template = [...fields];
    const genderItem = template.find(item => item.label === 'Gender');
    const index = template.findIndex(item => item.label === 'Gender');

    genderItem.disabled = true;

    if (event === 'MIXED RELAY (5K) (₹0)') {
      genderItem.disabled = false;
    }

    setValue(genderItem.name, 'Female');

    if (event === 'MENS RELAY (5K) (₹0)' || event === 'MIXED RELAY (5K) (₹0)') {
      setValue(genderItem.name, 'Male');
    }

    template.splice(index, 1, genderItem);

    setLocalEvent(event);
    setFields(template);
  }, [localEvent, event]);

  if (fields.length === 0) {
    return null;
  }

  return (
    <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mb-5">
      <h1 className="mb-6 text-lg font-bold">Runner {formId}</h1>
      <div className="grid grid-cols-12 gap-5 row-gap-5">
        {FormBuilder({
          template: {
            fields,
          },
          control,
        }).map(
          ({ field }, index) =>
            field && (
              <div key={index} className="col-span-12 sm:col-span-6">
                {field}
              </div>
            ),
        )}
      </div>
      {false && (
        <div className="flex justify-end mt-3">
          <button type="button" className="button" onClick={() => removeUserForm(formId)}>
            Remove Runner {formId}
          </button>
        </div>
      )}
    </div>
  );
}

Runner.propTypes = {
  formId: PropTypes.any.isRequired,
  participant: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  event: PropTypes.any.isRequired,
  setValue: PropTypes.any.isRequired,
  removeUserForm: PropTypes.any.isRequired,
};

export default Runner;
