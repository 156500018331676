import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import { getSession } from '../redux/saga/cognito/actions';

import { Header, Footer } from '../components/navigation';
import Router from '../router';

import titles from '../strings/title.json';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [title, setTitle] = useState(null);

  useEffect(() => {
    dispatch(getSession());
  }, []);

  useEffect(() => {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: false,
    });

    setTitle(`${titles.prefix} ${titles.page[location.pathname] || 'Loading...'}`);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <div className="main">
        <Router />
      </div>
      <Footer />
    </>
  );
}

export default App;
