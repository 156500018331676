/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import RadioGroupField from '../../../../../components/fields/radioGroup';

function EventSelection(props) {
  const { control, options } = props;

  return (
    <RadioGroupField
      name="event"
      control={control}
      label="Event Selection"
      options={options}
      required
    />
  );
}

EventSelection.propTypes = {
  control: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
};

export default EventSelection;
