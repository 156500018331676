/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormBuilder from '../../../../helper/formBuilder';

import { RADIO_GROUP_FIELD } from '../../../../constants/form/types';

function RaceRegistrationUser({ onChange }) {
  const { user } = useSelector(state => state.session);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      user: null,
    },
  });

  const selection = watch('user');

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setValue('user', 'Self');
    } else {
      setValue('user', 'Someone Else');
    }
  }, [user]);

  useEffect(() => {
    if (selection) {
      onChange(selection);
    }
  }, [selection]);

  return (
    <>
      {FormBuilder({
        template: {
          fields: [
            {
              type: RADIO_GROUP_FIELD,
              name: 'user',
              label: 'Who are you registering for?',
              options: [
                {
                  id: 'd5309c01-0347-4778-a9d7-7cc4f6a3051e',
                  name: 'Self',
                },
                {
                  id: '813279bc-fe1b-4855-bd6d-490df731101d',
                  name: 'Someone Else',
                },
              ],
            },
          ],
        },
        control,
      }).map(({ field }, index) => (
        <div key={index} className="col-span-12">
          {field}
        </div>
      ))}
    </>
  );
}

RaceRegistrationUser.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RaceRegistrationUser;
