/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import CouponCode from '../../../../../components/custom/coupon';
import Button from '../../../../../components/button';

function Summary(props) {
  const { id, total, gstRate, processingFee, registering } = props;

  const [coupon, setCoupon] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  const calculateTotal = useCallback(
    () => total.reduce((accumulator, currentValue) => accumulator + (currentValue?.fee || 0), 0),
    [total],
  );

  const calculateDiscount = useCallback(() => {
    let offer = 0;

    if (coupon) {
      const { type, discount } = coupon;

      if (type === 'PERCENT') {
        offer = calculateTotal() * (discount / 100);
      }

      if (type === 'AMOUNT') {
        offer = discount;
      }
    }

    return offer;
  }, [total, coupon]);

  const addGST = useCallback(() => {
    const totalCost = total.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.fee || 0),
      0,
    );
    return ((totalCost - calculateDiscount()) * gstRate) / 100;
  }, [total, coupon, gstRate]);

  const totalClean = total.filter(item => Object.keys(item).length);

  return (
    <div className="bg-white shadow-lg rounded-md border border-gray-300 p-6">
      <h1 className="mb-4 text-lg font-bold">Summary</h1>
      {totalClean.length > 0 ? (
        <>
          <table className="summary w-full text-sm">
            <thead>
              <tr className="text-white">
                <th>Item</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {totalClean.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>₹{item.fee}</td>
                </tr>
              ))}
              {coupon && (
                <tr key="discount">
                  <td>Discount ({coupon.code})</td>
                  <td>-₹{calculateDiscount().toFixed(2)}</td>
                </tr>
              )}
              {addGST && gstRate !== 0 && (
                <tr key="gst">
                  <td>GST ({gstRate}%)</td>
                  <td>₹{addGST().toFixed(2)}</td>
                </tr>
              )}
              {Boolean(processingFee) && (
                <tr key="gst">
                  <td>Processing Fee</td>
                  <td>₹{processingFee}</td>
                </tr>
              )}
            </tbody>
          </table>
          {false && <CouponCode raceID={id} coupon={coupon} onApply={setCoupon} />}
          <p className="font-medium text-base text-center mt-1 mb-3">
            Total: <span>&#8377;</span>
            {(
              calculateTotal() -
              calculateDiscount() +
              (addGST ? addGST() : 0) +
              processingFee
            ).toFixed(2)}
          </p>
          <div className="flex justify-center">
            <Button
              label="Register"
              fullWidth={isMobile}
              loading={registering}
              disabled={registering}
            />
          </div>
        </>
      ) : (
        <p className="text-sm text-gray-600 text-center mb-3">No Items Added</p>
      )}
    </div>
  );
}

Summary.propTypes = {
  id: PropTypes.any.isRequired,
  total: PropTypes.any.isRequired,
  gstRate: PropTypes.any.isRequired,
  processingFee: PropTypes.any.isRequired,
  registering: PropTypes.any.isRequired,
};

export default Summary;
