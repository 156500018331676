/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import Input from 'react-input-mask';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function InputMaskField({ control, name, label, mask, tooltip, disabled, required }) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="input-mask-field">
      <label htmlFor={name} className="text-sm font-bold text-gray-800">
        {label}{' '}
        {required && (
          <span className="text-red-600" style={{ marginLeft: '2px' }}>
            *
          </span>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              style={{ marginLeft: '2px' }}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Tooltip>
        )}
      </label>
      <Input
        name={name}
        className={classNames(
          'w-full px-2 border placeholder-gray-600 text-base rounded',
          fieldState.error ? 'border-red-600' : 'border-gray-300',
        )}
        mask={mask}
        maskChar=""
        value={field.value || ''}
        onChange={field.onChange}
        disabled={disabled}
      />
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

InputMaskField.defaultProps = {
  tooltip: null,
  disabled: false,
  required: false,
};

InputMaskField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default InputMaskField;
