export const FIELD_REQUIRED = 'Field Required';

export const INVALID_EMAIL = 'Invalid Email';
export const INVALID_PASSWORD = 'Invalid Password';
export const INVALID_PHONE_NUMBER = 'Invalid Phone Number';
export const INVALID_DATE = 'Invalid Date';
export const INVALID_PAN = 'Invalid PAN Number';

export const MINIMUM_AGE_ERROR = 'Age cannot exceed minimum age of';
export const MAXIMUM_AGE_ERROR = 'Age cannot exceed maximum age of';

export const RADIO_NO_ERROR = 'Cannot proceed as selection is "No"';
export const RADIO_YES_ERROR = 'Cannot proceed as selection is "Yes"';

export const CHECKBOX_NO_ERROR = 'Cannot proceed as checkbox is "Unchecked"';
