/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import { parse } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { loginUser } from '../../../services/cognito';

import { resendConfirmation } from '../../../services/apis/directory/user';

import { sessionActive } from '../../../redux/reducer/session';
import { notificationError } from '../../../redux/saga/notification/actions';

import FormBuilder from '../../../helper/formBuilder';

import Schema from '../../../schema/form/auth/login';

import Button from '../../../components/button';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: Schema.DefaultValues,
    resolver: yupResolver(Schema.YupSchema),
  });

  const resend = useCallback(async email => {
    try {
      const response = await resendConfirmation({
        email,
        token: null,
        abandoned: true,
      });

      const { token } = response;

      setLoading(false);
      navigate(`/confirm-registration?token=${token}`);
    } catch (_error) {
      setLoading(false);
    }
  }, []);

  const login = useCallback(async (email, password) => {
    setLoading(true);

    try {
      const response = await loginUser(email, password);

      const refreshToken = response.getRefreshToken().getToken();
      const accessToken = response.getAccessToken().getJwtToken();
      const idToken = response.getIdToken().getJwtToken();
      const user = response.getIdToken().payload;

      dispatch(
        sessionActive({
          refreshToken,
          accessToken,
          idToken,
          user: {
            id: user.sub,
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email,
            emailVerified: user.email_verified,
            phoneNumber: user.phone_number,
            phoneNumberVerified: user.phone_number_verified,
            dob: parse(user.birthdate, 'dd/MM/yyyy', new Date()),
            gender: user.gender,
            picture: user.picture || null,
          },
        }),
      );

      setLoading(false);
    } catch (error) {
      dispatch(notificationError(error.message));

      if (error.name === 'UserNotConfirmedException') {
        resend(email);
      } else {
        setLoading(false);
      }
    }
  }, []);

  const onSubmit = useCallback(async data => {
    const { email, password } = data;

    login(email.trim(), password);
  }, []);

  return (
    <div className="mx-auto flex overflow-y-auto pt-10 pb-10">
      <div className="max-mobile-width sm:max-w-sm w-full mx-auto my-auto bg-white shadow-lg rounded-md border border-gray-300">
        <div className="overflow-hidden">
          <div className="pb-6 pt-6 pr-8 pl-8">
            <h1 className="text-xl text-black text-center mb-5 font-medium">Sign In</h1>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {FormBuilder({
                template: Schema.Template,
                control,
              }).map(({ field }, index) => (
                <div key={index} className="mb-4">
                  {field}
                </div>
              ))}
              <div className="flex justify-end mb-4">
                <Link to="/forgot-password" className="text-sm font-medium text--primary">
                  Forgot Password?
                </Link>
              </div>
              <Button label="Login" loading={loading} disabled={loading} fullWidth />
              <p className="mt-5 text-center text-sm font-medium">
                Not a member yet?{' '}
                <Link to="/register" className="text--primary">
                  Create Account
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
