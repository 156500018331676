/* eslint-disable import/prefer-default-export */
import http from '../../axios';

export const fetchParticipantSearch = async (raceID, search) =>
  http.get('/participant/search', {
    params: {
      raceID,
      search,
    },
  });
