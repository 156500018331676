import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import InputField from '../../fields/input';
import Button from '../../button';

import { fetchCouponSearch } from '../../../services/apis/business/coupon';

function CouponCode({ raceID, coupon, onApply }) {
  const [loading, setLoading] = useState(false);

  const { control, trigger, getValues } = useForm({
    mode: 'onChange',
  });

  const search = useCallback(async code => {
    setLoading(true);

    try {
      const response = await fetchCouponSearch(raceID, code);

      onApply(response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback(async () => {
    const valid = await trigger();

    if (valid) {
      const data = getValues();
      search(data.coupon);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '15px',
      }}>
      {!coupon ? (
        <>
          <InputField name="coupon" control={control} placeholder="Coupon Code" />
          <div style={{ marginLeft: '10px' }}>
            <Button
              type="button"
              label="Apply"
              loading={loading}
              fullWidth={false}
              disabled={loading}
              onClick={() => onSubmit()}
            />
          </div>
        </>
      ) : (
        <>
          <p>
            Applied <strong>{coupon.code}</strong>!
          </p>
          <div style={{ marginLeft: '10px' }}>
            <Button
              type="button"
              label="Remove"
              loading={false}
              fullWidth={false}
              disabled={false}
              onClick={() => onApply(null)}
            />
          </div>
        </>
      )}
    </div>
  );
}

CouponCode.defaultProps = {
  coupon: null,
};

CouponCode.propTypes = {
  raceID: PropTypes.string.isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string,
    type: PropTypes.string,
    discount: PropTypes.number,
  }),
  onApply: PropTypes.func.isRequired,
};

export default CouponCode;
