import React from 'react';

function TermsConditions() {
  return (
    <div className="container max-w-4xl mx-auto">
      <div className="px-10 pt-10 pb-10">
        <h1 className="text-3xl font-semibold mb-8">Racetime Terms Of Service</h1>
        <h1 className="text-base md:text-lg mb-2">
          <strong>Basic Definitions</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          This Terms of Use Agreement (&quot;Agreement&quot;) sets forth the legally binding terms
          for your use of the Racetime India Services. You are only authorized to use the Racetime
          India Services (regardless of whether your access or use is intended) if you agree to
          abide by all applicable laws and to this Agreement. Please read this Agreement carefully
          before using this website. By using the Racetime India Services, you agree to be bound by
          this Agreement, whether you are a &quot;Visitor&quot; (which means that you simply browse
          the Racetime India site) or you are a &quot;Member&quot; (which means that you have
          registered with Racetime India). The term &quot;User&quot; refers to a Visitor or a
          Member. Kindly print these terms of use or save a copy of these terms of service for your
          records. If you do not agree with any of these terms, you cannot avail of any of the
          Racetime India Services and you should leave the Racetime India site immediately. If you
          wish to become a Member, communicate with other Members and make use of the Racetime India
          Services, you must read this Agreement and indicate your acceptance during the
          Registration process.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          The term &apos;authorized user&apos; would include any person, whether an individual or a
          legal entity who has subscribed to the services of Racetime India, and to whom the access
          is restricted by the use of a sign in user name and a password. The user name and password
          are either allotted by Racetime India or chosen by the user or agreed upon by Racetime
          India. Therefore only the authorized user has the right to access the services offered by
          Racetime India. Racetime India will not be responsible for any costs, consequences or
          damages caused to any persons or entities due to any unauthorized use of the services
          offered by Racetime India, including access of the said services by an unauthorized user
          using the user name and password of an authorized user, regardless of whether such
          unauthorized user is known to the authorized user.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          The terms &apos;service&apos; or &apos;services&apos; would mean to include the
          interactive online solutions &amp; information services offered by Racetime India on the
          internet/mobile through which the user may access information carried by Racetime India in
          the database maintained by it.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          You agree not to copy, sell, resell, duplicate any part of Racetime India&apos;s services
          &amp; solutions for commercial purposes.
        </p>
        <p className="text-sm md:text-base font-medium">
          These Terms of Service sets out the legally binding terms of your use of Racetime India
          and your membership in Racetime India and may be modified by Racetime India at any time
          and without prior notice, such modifications to be effective upon posting by Racetime
          India on the website. In the event of any inconsistency between our Privacy Policy and
          these Terms of Service, the Terms of Service shall prevail. If you do not agree to be
          bound by the terms of this Agreement, you may not use nor access our services.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Eligibility</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Racetime India services are available to all individuals who are at least 10 years of age.
          By registering to use Racetime India services, you represent and warrant that you are at
          least 10 years of age. Racetime India has the right, in its sole discretion, to suspend or
          terminate your use of Racetime India services and refuse any and all current or future use
          of all or any portion of Racetime India&apos;s services.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Service</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Racetime India provides self-managed event organizing solutions to event organizers. Event
          organizers setup their event, fill up the details about their event such as event pricing,
          location, ticket inventory, attendee registration information collection and other such
          features. As used herein, &quot;Customers&quot; means Racetime India event organizers
          (&quot;Event Organizer&quot;), registered event attendees (&quot;Attendee&quot;) and
          participants in our various affiliate programs.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Event Organizer Terms</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          Racetime India offers organizers a web-based solution to organize events and meetings, to
          sell tickets or provide online Attendee Registration solutions via the Racetime
          India-Websites for events of the organizer to customers by using Racetime India to take on
          the payment process. In detail Racetime India provides the following services to the
          organizer (each an &quot;Racetime India-Service&quot;):
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          A web-based software on the Racetime India-websites to organize events and to sell tickets
          of the organizer for these events to ticket buyers. The organizer has the possibility to
          create an own website for their event (&quot;eventsite&quot;) and to offer a ticket sale.
          The ticket buyer can choose and buy the tickets on the eventsite directly.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Only the user and the organizer of an event are entering into an agreement regarding the
          event and the disposal of tickets for that event. Racetime India only acts as a sales
          agent for the organizer of an event by order and for account of this organizer. It is the
          organizers&apos; duty to advise and instruct the ticket buyers of their eventually
          existing right of objection according to the legal guidelines.
        </p>
        <p className="text-sm md:text-base font-medium">
          The organizer authorises Racetime India hereby for the duration of this agreement to act
          as an agent by order and for account of the organizer, to sell tickets to the public for
          events, offered by the organizer on the Racetime India-Websites, to undertake the payment
          process with the ticket buyers and/or their banks and to ship the tickets to the ticket
          buyers. By providing Racetime India with such authorization, organizer also agrees to the
          Stripe Connected Account Agreement . This authorization also contains the right of
          Racetime India to send payment reminders to the ticket buyers.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Shipment &amp; Delivery</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          The organizer can choose, whether the tickets shall be deposited on the venue or shall be
          sent electronically or if the ticket buyer shall have the choice between these different
          shipment methods. As far as tickets shall be deposited on the venue the organizer has the
          right to choose, whether the tickets shall be paid on the venue and/or in advance via
          Racetime India.
        </p>
        <p className="text-sm md:text-base font-medium">
          At the option of the organizer, Racetime India can also offer offline ticketing i.e.
          Racetime India will take all tickets from the Organiser, offer a set number of the tickets
          online as suggested by the organizer, or as deemed suitable by Racetime India, and put the
          remaining tickets into its retail associate networks (such as any cafes, bistros etc. with
          which Racetime India may tie up). The Event organizer shall decide upon whether it wants
          to avail of only online/electronic ticketing solutions provided by Racetime India or
          whether it prefers Racetime India to provide both paper version &amp; electronic version
          ticketing services.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Payment &amp; Fees</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          Racetime India products &amp; services are available on the basis of transaction model, as
          per the requirements of customers.
        </p>
        <p className="text-sm md:text-base font-medium">
          Racetime India will be responsible only for the taxes applicable on Racetime India Fees
          (including TS fee, PG fee and fixed ticket charge). Organizer will bear the full
          responsibility of the taxes applicable on ticket price and Racetime India can not be held
          accountable for any inaccurate information provided by organizer for such taxes.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Transaction Model</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          In this model, pricing is applied on each ticket sale/attendee registration.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          The organizer has to determine the base price for his tickets. This base price belongs to
          the organizer as far as nothing different is stated in these terms. The organizer can
          choose whether the additional fees payable to Racetime India (fees for accounting,
          shipment etc.) are charged on top of this base price or not.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Racetime India charges a fee for every ticket sold via the Racetime India-Websites (A
          convenience-fee) as stated in the price list published on the Racetime India-Websites.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          In addition to that Racetime India charges a fee for accounting tickets according to
          clause 4 as well as for using the Racetime India-Websites by the organizer (A
          ticketing-fee) as stated in the price list published on the Racetime India-Websites. The
          rate of the fee depends by the payment method chosen by the organizer. The ticketing-fee
          is charged even if the tickets shall be deposited and paid on the venue.
        </p>
        <p className="text-sm md:text-base font-medium">
          In the event that tickets for an event have been sold via the Racetime India-Websites, and
          the event is cancelled for any reason not directly attributable to Racetime India, then
          Racetime India shall continue to be entitled to the convenience fees and ticketing fees.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Change of an event</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          If an event for which tickets are sold or already have been sold via Racetime India shall
          be cancelled or changed (regarding date, time, venue or other important points) the
          organizer has to inform Racetime India immediately in writing once these circumstances
          have come to his attention.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Accounting</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          The organizer has options of various payment method(s) (credit card, direct debiting,
          prepayment, invoice) to be offered to possible ticket buyers. In accordance to this choice
          Racetime India undertakes accounting for the organizer and holds the money on a separate
          bank account. The organizer authorises Racetime India to do so. Racetime India transfers
          the money received according to service pricing minus the fees payable to Racetime India
          within 5 to 7 days to the organizer. The money has to be transferred to a bank account
          located in the country where the event took place and issued on the name of the organizer.
          For recurring events, Racetime India reserves the right to pay out money before the event.
          Racetime India reserves the right to change the number of days to transfer money to the
          event organizer at any time without notice. The organizer is aware of the risk that
          specific payment methods (direct debiting or credit card) can be reversed by the ticket
          buyers. The organizer bears this risk alone. If transactions are reversed after the
          payment to the organizer, these costs plus a fee for the reversed transaction as stated in
          the price list published on the Racetime India-Websites are charged to the organizer.
        </p>
        <p className="text-sm md:text-base font-medium">
          The organizer is obliged to raise objections, if any, against the accounts of Racetime
          India within four weeks after issue of the account. If not the account is considered as
          being approved.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Prevention against fraud / misusage</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          The Racetime India-Websites offer an extensive security system, in particular to protect
          organizers against reimbursement of credit card payments. Because of this security system
          it is possible that in exceptional cases some credit card providers may not be accepted.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          If the organizer arouses suspicion of misusage of the Racetime India-Websites, Racetime
          India has the right to deactivate the ticket shop of the organizer and to stop the ticket
          selling. In particular a suspicion of misusage of the Racetime India-Websites is on hand,
          if it comes to attention before the event that the event shall not (or not in the way as
          stated in the ticket shop) take place; or illegal or immoral events shall take place; or
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          If the organizer arouses suspicion of misusage of the Racetime India-Websites, Racetime
          India has the right to delay the payout to the organizer even after 5 to 7 days for
          further 60 days. Within these 60 days Racetime India will check if an improper use of the
          Racetime India-Websites and / or Racetime India-Services occurred. Racetime India also
          reserves the right to block any event from its platform, if the event is found to be
          misleading to Racetime India users. (For example: if an event is listed as free and an
          organizer forces attendees to pay for the event separately, the listed event is deemed to
          be misleading to the event attendees.)
        </p>
        <p className="text-sm md:text-base font-medium">
          To verify your identity, we ask for a valid ID proof like Passport or driving license. We
          require you to upload a copy of your valid ID proof, it will help us in identifying you to
          a high degree of fidelity as the inability to accurately identify individual pose risks
          with respect to fraudulent claims, tax compliance, and creation or listing of valid events
          on Racetime India.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Warranty of the organizer</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          The organizer warrants to Racetime India, that (i) there is no agreement between the
          organizer and a venue, or the owner or user of a venue, or a third party, which affects
          the organizer&apos;s use of the Racetime India-Services in accordance with these terms,
          (ii) the organizer is allowed to enter into this agreement with Racetime India, including
          the right to authorize Racetime India in accordance to these terms to act as an agent and
          to sell tickets for events of the organizer.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Duties of the organizer</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          The organizer has the duty to inform Racetime India immediately about unavailability or
          malfunctions of the Racetime India-Websites.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          The organizer has the obligation to inform Racetime India immediately about every change
          of his name, company name, address, legal form or bank details in written form or via
          E-Mail. The organizer shall not use the Racetime India-Services for illegal or immoral
          events such as political or religious extremism, pornographic, or violent events.
        </p>
        <p className="text-sm md:text-base font-medium">
          The organizer shall not use the Racetime India-Services to upload, post, email, transmit
          or otherwise make available any Information that is unlawful, harmful, threatening,
          abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of
          another&apos; privacy, hateful, or racially, ethnically or otherwise objectionable; The
          organizer and/or organizer&apos; event will not contain and/or promote any sexually
          explicit or sexually suggestive content, including pornographic material, &quot;adult
          friend finders&quot; or dating sites with a sexual emphasis, &quot;adult&quot; toys or
          sexually explicit videos; The organizer and/or organizer&apos; event will not contain
          and/or promote any way of provision or selling of drugs or prohibitive substances; The
          organizer shall not use the Racetime India-Services to harm minors in any way; The
          organizer shall not impersonate any person or entity, including, but not limited to, an
          Racetime India representative, forum leader, or falsely state or otherwise misrepresent
          its affiliation with a person or entity; The organizer shall not forge headers or
          otherwise manipulate identifiers in order to disguise the origin of any Information
          transmitted through the Service;
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Duration</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Furthermore Racetime India has the exclusive right to cancel this agreement regarding the
          provision of Racetime India-Services in case of an important reason. In particular such an
          important reasons shall exist, if the organizer has contravened against important
          provisions of these terms; or the organizer has filed for bankruptcy, insolvency
          proceedings have been started or the start of the insolvency proceedings has been
          dismissed in default of assets; or circumstances occur that the organizer can not fulfill
          his contractual obligations against Racetime India or third parties in default of assets,
          and in addition the organizer gives no proof of sufficient assets within 30 days after
          request by Racetime India to do so.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Cancellation</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          If you desire to cancel your services, please email team@racetime.in 30 days before expiry
          of the service. There is no cancellation fee. Once your account is cancelled, your Content
          may not be recovered. There will be no refund if you cancel the Service before the end of
          your current, paid-up month or year, and you will not be charged or billed thereafter.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Ticket Buyer Terms</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          The price payable for the tickets is as set out on our web site.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Tickets will normally be available for collection at a designated location prior to the
          event, in which case you must collect the tickets from the address at the time advised,
          and provide proof of ID. Under some circumstances events may decide to distribute tickets
          via post or other methods, in which case only the card billing address supplied will be
          used for dispatch.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Tickets may not be dispatched immediately where we are acting as an agent on behalf of a
          promoter and that promoter gives alternative instructions about the dispatch of tickets.
          However, you will be given notification of dispatch once you have ordered your tickets.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Once you have received your tickets, you should check them and you must inform us of any
          errors within two working days, or before the event, whichever is sooner. The event
          organizer, the venue where the event is organized reserve the right to refuse admission to
          you; request latecomers to await admission to the event until a convenient break in the
          event; refuse re-admission to those leaving during the event; and request that you leave
          the event; make alterations to the advertised details of the event (including Principals)
          and make alterations to the script or the content of any particular event at any time up
          to and including and during the event.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          If, at any time during the event it is cancelled, postponed, suspended or delayed for any
          reason, Racetime India will not make any refunds nor will they be liable for any loss
          caused by such cancellation, postponement, suspension or delay.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          It is your responsibility to ascertain whether an event has been cancelled and the date
          and time of any rearranged event. If an event is cancelled or rescheduled, we will use
          reasonable endeavours to notify ticket holders of the cancellation once we have received
          the relevant authorisation from the Event Organiser. We do not guarantee that ticket
          holders will be informed of such cancellation before the date of the event.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Tickets are issued subject to the Rules and Regulations of the venue or Event Organiser.
          Full details are available from the Event Organisers. Breach of any of these Rules and
          Regulations or any unacceptable behaviour likely to cause damage, nuisance or injury shall
          entitle the venue or Event Organiser to eject you from the venue. The Event Organisers may
          on occasions have to conduct security searches to ensure the safety of the patrons. We
          will not be responsible for any tickets that are lost or stolen.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          The venue, Event Organiser and Racetime India accept no responsibility for loss of any
          personal property.
        </p>
        <p className="text-sm md:text-base font-medium">
          Tickets may be restricted to a maximum number per person, per credit card and, for some
          events, a restriction may apply per household. We reserve the right to cancel tickets
          without prior notice purchased in excess of this number.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>General Terms</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Advertising Material Racetime India would not be held liable for any inappropriateness of
          the advertisement contents due to the dynamic nature of web, invisible/hidden contents,
          error, omission or inaccuracy in printed or digital form. Racetime India reserves the
          right to remove and/or reposition the advertising material.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Safe Harbor</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          Any statements contained within the Site concerning Racetime India&apos;s future prospects
          are forward-looking statements which involve a number of risks, uncertainties and other
          factors that could cause actual results to differ materially from those that may be
          projected by these forward looking statements. There can be no assurance that future
          results will be achieved and actual results could differ materially from forecasts,
          estimates and summary information contained in the site.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Intellectual Property Rights</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          You agree that Racetime India owns all rights to the code, databases, visual-design and
          layout of the Service.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Racetime India claims no intellectual property rights over the material you provide to the
          Service. Your profile and materials uploaded remain yours.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          You may not use any robot, spider, other automated device, or manual process to monitor or
          copy any Content from the Service.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          You may not resell, duplicate or reproduce or exploit any part of the Service without the
          written consent permission of Racetime India.
        </p>
        <p className="text-sm md:text-base font-medium">
          You may not duplicate, copy, or reuse any portion of the visual design or layout of the
          Service without the prior written permission of Racetime India.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Information Disclosure</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          To the extent required or permitted by law, Racetime India may also collect, use and
          disclose personal information in connection with security related or law enforcement
          investigations or in the course of cooperating with authorities or complying with legal
          requirements.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Disclaimer of Warranties</strong>
        </h1>
        <p className="text-sm md:text-base font-medium mb-3">
          You understand and agree that the Racetime India services are provided on an &quot;AS
          IS&quot; and &quot;AS AVAILABLE&quot; basis and that the Racetime India does not assume
          any responsibility for prompt or proper delivery of services. Racetime India does not
          warrant that its websites will operate error-free or free of computer viruses or
          mal-functioning or harmful mechanisms. All the contents of website are only for general
          information or use. Such information does not constitute advice and should not be relied
          upon in making or derived decisions. Any specific advice or replies to queries in any part
          of the website are the personal opinion of users and are not subscribed to by Racetime
          India. This website assumes no responsibility for the accuracy or existence of any
          communications between users. Racetime India expressly disclaims all warranties of any
          kind, whether express or implied, including, but not limited to the implied warranties of
          merchantability, fitness for a particular purpose and non-infringement.
        </p>
        <p className="text-sm md:text-base font-medium mb-3">
          Racetime India makes no warranty that the services will meet your requirements, service
          will be uninterrupted, timely, secure or error-free. Certain links on website lead to
          resources located on servers maintained by third parties over whom Racetime India has no
          control or connection, business or otherwise as these sites are external to this website
          you agree and understand that by visiting such sites you are beyond this website. Racetime
          India therefore neither endorses nor offers any judgment or warranty and accepts no
          responsibility or liability for the authenticity/availability of any of the
          goods/services/or for any damage, loss or harm, direct or consequential or any violation
          of local or international laws that may be incurred by your visit and/or transaction/s on
          these sites.
        </p>
        <p className="text-sm md:text-base font-medium">
          Any material downloaded or otherwise obtained through the use of this website is done at
          your discretion and risk that you will be solely responsible for any damage to your
          computer system or loss of data that results from the download of any such material.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Limitation of Liability</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          You agree that Racetime India shall, in no event, be liable for any consequential,
          incidental, indirect, special, punitive or other loss or damage whatsoever or for the loss
          of business profits, business interruptions, computer failure, loss of business
          information, or other loss arising out of or caused by your use of or inability to use
          this website, even if Racetime India has been advised of the possibility of such damage.
          The Venue, event organizers, events services &apos;marketplace&apos; information provided
          on Racetime India are added by members. Racetime India doesn&apos;t verify, claim or
          guarantee authenticity of such information. Racetime India or Signure is not liable for
          the acts, errors, omissions, representations, warranties, breaches or negligence of any
          such suppliers or for any personal injuries, death, property damage, or other damages or
          expenses resulting there from. Racetime India have no liability and will make no refund in
          the event of any delay, cancellation, overbooking, strike, force majeure or other causes
          beyond their direct control, and they have no responsibility for any additional expense,
          omissions, delays, re-routing or acts of any government or authority. Racetime India makes
          no representations or warranties as to the completeness or accuracy of Information
          contained on the website. The service provided is of such a nature that service can be
          interrupted for many reasons other than the negligence of the company and that damages
          resulting from any interruption of service are difficult to ascertain. Therefore, you
          agree that Racetime India shall not be liable for any damages arising from such causes
          beyond the direct and exclusive control of the Racetime India. You further acknowledge
          that Racetime India&apos;s liability for its own negligence may not in any event exceed an
          amount equivalent to charges payable by you for services during the period damages
          occurred. In no event shall the Racetime India be liable for any special or consequential
          damages, loss or injury.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Indemnity</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          You agree to indemnify and hold Racetime India, its parent (&quot;Signure&quot;), its
          subsidiaries and affiliates, and each of their directors, officers, agents, contractors,
          partners and employees, harmless from and against any loss, liability, claim, demand,
          damages, costs and expenses, including reasonable attorney&apos;s fees, arising out of or
          in connection with any User Content, any Third Party Applications, Software or Content you
          post or share on or through the website (including through the Share Service), your use of
          this website, your conduct in connection with this website or with other users of the
          website , or any violation of this Agreement or of any law or the rights of any third
          party.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Termination</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          You agree that Racetime India may terminate your Member Account and access to the site for
          reasons including, but not be limited to, breaches or violations of the Terms or the
          Racetime India Privacy Policy, a request by you to terminate your Account, discontinuance
          or material modification to the Services, unexpected technical issues or problems,
          extended periods of inactivity and requests by law enforcement or other government
          agencies. Termination of your Racetime India Account includes elimination of access to the
          Service, deletion of your Account information such as your e-mail ID and Password and
          deletion of data in your Member Account as permitted or required by law.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Applicable Laws</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          All matters relating to your access to and use of the Racetime India site shall be
          governed by the laws of the Republic of India. You hereby irrevocably consent to the
          exclusive jurisdiction and venue of courts in Bangalore, India in all disputes arising out
          of or relating to the use of Racetime India. Use of Racetime India is unauthorized in any
          jurisdiction that does not give effect to all provisions of these terms and conditions,
          including without limitation this paragraph.
        </p>
        <h1 className="text-base md:text-lg mt-6 mb-2">
          <strong>Notice &amp; Take Down Policy</strong>
        </h1>
        <p className="text-sm md:text-base font-medium">
          AS AN INTERMEDIARY, RACETIME INDIA IS NOT LIABLE FOR ANY THIRD-PARTY CONTENT UPLOADED,
          TRANSMITTED, DISPLAYED, PUBLISHED OR SHARED ON ITS PLATFORM. BY USING RACETIME.IN, YOU
          AGREE TO THE CONDITIONS SET OUT IN THIS NOTICE AND TAKE DOWN POLICY
        </p>
        <ol className="text-sm md:text-base font-medium list-decimal list-outside mt-6">
          <li style={{ marginBottom: '8px' }}>
            <h1 className="text-sm mb-2">
              <strong>COPYRIGHT INFRINGEMENT NOTIFICATION</strong>
            </h1>
            <p className="font-medium">
              If the owner of a copyright-protected work or an agent authorized to act on the
              copyright owner’s behalf (the “Owner”) believes the work posted on racetime.in (“the
              Site”) is infringing the Owner’s copyright under the relevant law, the Owner may
              submit a copyright infringement notification.
            </p>
          </li>
          <li style={{ marginBottom: '8px' }}>
            <h1 className="text-sm mb-2">
              <strong>COPYRIGHT INFRINGEMENT NOTIFICATION PROCEDURE</strong>
            </h1>
            <p className="font-medium mb-2">
              Racetime India recommends Owners of copyright-protected content to report alleged
              copyright infringement by submitting a written complaint (the “Complaint”) either
              through a web-form (recommended) or through email on team@Racetime.in The Complaint
              should include the following:-
            </p>
            <p className="font-medium mb-2">
              Contact Information: The Owner will need to provide information that will allow
              Racetime India and the uploader(s) of the video(s) that the Owner’s objects to, to
              contact the Owner regarding the Complaint, such as an email address, physical address
              or telephone number
            </p>
            <p className="font-medium mb-2">
              Description of Work: The Owner’s must provide in the complaint, a complete and
              accurate description of the copyrighted content that the Owner is seeking to protect.
              This MUST also include the specific URL of the video that the Owner believes to be
              infringing. This is necessary in order to aid Racetime India to locate the allegedly
              infringing video. General information about the video, such as a channel URL or
              username alone is not adequate.
            </p>
            <p className="font-medium mb-2">
              Necessary Format: The Complaint should include the following statements – “I have a
              good faith belief that the use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law.” AND “The information in
              this notification is accurate, and I am the owner, or an agent authorized to act on
              behalf of the owner, of an exclusive right that is allegedly infringed.”
            </p>
            <p className="font-medium">
              Signature: The Complaint must include the physical or electronic signature/digital
              signature of the Owner. To satisfy this requirement, the Owner may type their full
              legal name (a first and last name, not a company name) as the signature at the bottom
              of the complaint
            </p>
          </li>
          <li style={{ marginBottom: '8px' }}>
            <h1 className="text-sm mb-2">
              <strong>TAKE-DOWN OF COPYRIGHT INFRINGING WORK</strong>
            </h1>
            <p className="font-medium">
              In case of a Complaint by the Owner that is submitted in accordance with the procedure
              in Clause 2, and found to be valid, Racetime India may refrain from facilitating
              access to the allegedly infringing work for a period of 21 (twenty one) days or until
              an order from a competent court is received, whichever is earlier. In case Racetime
              India receives no such order before the expiry of twenty-one days, Racetime India may
              reinstate access to the said work.
            </p>
          </li>
          <li style={{ marginBottom: '8px' }}>
            <h1 className="text-sm mb-2">
              <strong>NOTIFICATION OF OBJECTIONABLE CONTENT AND TAKE DOWN BY RACETIME INDIA</strong>
            </h1>
            <p className="font-medium mb-2">
              Users may submit a complaint against objectionable content by submitting a written
              complaint (the “Complaint”) either through a web-form (recommended) or through email
              on team@racetime.in The Complaint should include the following:
            </p>
            <p className="font-medium mb-2">
              Contact Information: The Complainant will need to provide information that will allow
              Racetime India to contact the Complainant regarding the Complaint, such as an email
              address, physical address or telephone number.
            </p>
            <p className="font-medium mb-2">
              Description of Work: The Complainant must provide in the complaint, a description of
              the objectionable content that the Complainant is seeking to protest. This MUST also
              include the specific URL of the video that the Complainant believes to be
              objectionable. This is necessary in order to aid Racetime India to locate the
              allegedly objectionable video. General information about the video, such as a channel
              URL or username alone shall not be adequate. The URL should be in the following
              format: http://www.Racetime.in/_____________/
            </p>
            <p className="font-medium mb-2">
              Necessary Format: The Complaint should include the following statements – “I have a
              good faith belief that the use of the material in the manner complained of violates
              any law, or the conditions in Clause 4 of Racetime India’s Notice and Take Down
              Policy” AND “The information in this notification is accurate to my knowledge”
            </p>
            <p className="font-medium">
              If Racetime India determines, in its sole discretion and judgment, that the User
              Content violates any of the aforementioned conditions, it shall be entitled to
              immediately take down or otherwise block access to User Content
            </p>
          </li>
          <li>
            <h1 className="text-sm mb-2">
              <strong>RACETIME INDIA IMMUNITY FROM LIABILITY</strong>
            </h1>
            <p className="font-medium mb-2">
              Racetime India is an intermediary and shall not be liable for any act of copyright
              infringement or any other objectionable User Content, as provided under the Copyright
              Act, 1957, Information Technology Act, 2000 and Rules thereunder.
            </p>
            <p className="font-medium mb-2">
              Take Down Points for Racetime India. Timing Any Race day and BIB Expo day concerns not
              related to Race Timing, Timing Chip, Race Numbers will not be under the purview of
              Racetime India Timing.
            </p>
            <p className="font-medium mb-2">
              Racetime India Timing under no circumstance can be held liable for any mishap on race
              day causing inconvenience to Runners.
            </p>
            <p className="font-medium mb-2">
              Issues related to Route, Pacers, Price Money, Hydration, Medical support and other
              concerns not related to Timing will not be under the purview of Racetime India Timing.
            </p>
            <p className="font-medium mb-2">
              Organiser is liable to make 80% of the Invoice amount at least 5 days before the event
              and balance of 20% within 5 days post event.
            </p>
            <p className="font-medium mb-2">
              Racetime India holds all rights to block registration amount equivalent to Timing
              Invoice balance payment. The communication for the same will be shared with the
              organiser prior to the block.
            </p>
            <p className="font-medium mb-2">
              Dates for Timing services will be blocked with the mutual consent of Racetime India
              and the Organizer. In case of cancellation by Organizer, Organiser will be liable to
              pay a fixed penalty of Rs.20,000. Racetime India holds all rights to deduct the same
              from the Registration amount, if not cleared before the event.
            </p>
            <p className="font-medium">
              In case of cancellation by Organizer, all the expenses made by Racetime India Timing
              till date will be charged to the organiser. This will include charges like (not
              limited to) BIB Printing, Chip Allocation, Marketing activities, Offline data entry
              support.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsConditions;
