/* eslint-disable func-names */
import * as yup from 'yup';

import { INPUT_MASK_FIELD } from '../../../../constants/form/types';

import { FIELD_REQUIRED } from '../../../../constants/validation/errors';

const ConfirmRegistration = {
  YupSchema: yup.object().shape({
    code: yup.string().required(FIELD_REQUIRED),
  }),
  DefaultValues: {
    code: '',
  },
  Template: {
    fields: [
      {
        type: INPUT_MASK_FIELD,
        name: 'code',
        label: 'Code',
        mask: '999999',
      },
    ],
  },
};

export default ConfirmRegistration;
