/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../loader/spinner';

function LoadingButton(props) {
  const { type, label, loading, fullWidth, disabled, onClick } = props;

  return (
    <button
      type={type ?? 'submit'}
      className={classNames(`button ${fullWidth ? 'button--full-width' : ''}`)}
      disabled={disabled}
      onClick={onClick}>
      {loading ? <Spinner color="white" /> : label}
    </button>
  );
}

LoadingButton.defaultProps = {
  type: null,
  onClick: null,
};

LoadingButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default LoadingButton;
