/* eslint-disable func-names */
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { INPUT_FIELD, INPUT_MASK_FIELD, DROPDOWN_FIELD } from '../../../../constants/form/types';

import { FIELD_REQUIRED, INVALID_PHONE_NUMBER } from '../../../../constants/validation/errors';

import Relationship from '../../../../data/master/relationship.json';

const Emergency = {
  YupSchema: yup.object().shape({
    name: yup.string().required(FIELD_REQUIRED),
    phone: yup
      .string()
      .required(FIELD_REQUIRED)
      .test('phone-validate', null, function (value) {
        if (value && value.length > 1) {
          const number = parsePhoneNumberFromString(value, 'IN').formatInternational() || '';

          if (parsePhoneNumberFromString(number).isValid()) {
            return true;
          }

          return this.createError({
            path: this.path,
            message: INVALID_PHONE_NUMBER,
          });
        }

        return false;
      }),
    relationship: yup.object().nullable().required(FIELD_REQUIRED),
  }),
  DefaultValues: {
    name: '',
    phone: '',
    relationship: null,
  },
  Template: {
    fields: [
      {
        type: INPUT_FIELD,
        name: 'name',
        label: 'Full Name',
        required: true,
      },
      {
        type: INPUT_MASK_FIELD,
        name: 'phone',
        label: 'Phone Number',
        mask: '9999999999',
        required: true,
      },
      {
        type: DROPDOWN_FIELD,
        name: 'relationship',
        label: 'Relationship',
        options: Relationship,
        required: true,
      },
    ],
  },
};

export default Emergency;
