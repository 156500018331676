/* eslint-disable import/prefer-default-export */
import http from '../../axios';

export const fetchCouponSearch = async (raceID, code) =>
  http.get('/coupon/search', {
    params: {
      raceID,
      code,
    },
  });
