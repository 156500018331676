import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from '../../components/loader/spinner';

function PrivateRoute({ children }) {
  const location = useLocation();

  const session = useSelector(state => state.session);

  if (session.loading) {
    return (
      <div className="min-height flex items-center">
        <Spinner color="black" size="large" />
      </div>
    );
  }

  return session.logged ? children : <Navigate to="/login" state={{ from: location }} replace />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
