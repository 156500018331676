/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Tooltip } from 'react-tippy';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function InputField({
  control,
  name,
  label,
  padding,
  paddingText,
  tooltip,
  placeholder,
  onKeyDown,
  disabled,
  required,
}) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="input-field" style={{ flex: 1 }}>
      {label && (
        <label htmlFor={name} className="text-sm font-bold text-gray-800">
          {label}{' '}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
          {tooltip && (
            <Tooltip title={tooltip}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                style={{ marginLeft: '2px' }}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </Tooltip>
          )}
        </label>
      )}
      <div
        className={classNames(
          'flex border text-base rounded mt-1 mb-1',
          fieldState.error ? 'border-red-600' : 'border-gray-300',
        )}>
        {padding && (
          <div className="bg-gray-200 text-gray-600 flex items-center px-2">{paddingText}</div>
        )}
        <input
          name={name}
          className={classNames('w-full px-2 placeholder-gray-600')}
          placeholder={placeholder}
          value={field.value || ''}
          onChange={field.onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
      </div>
      {fieldState.error && (
        <p className="text-red-600 text-xs text-left font-semibold">{fieldState.error?.message}</p>
      )}
    </div>
  );
}

InputField.defaultProps = {
  label: null,
  padding: false,
  paddingText: null,
  tooltip: null,
  placeholder: null,
  onKeyDown: null,
  disabled: false,
  required: false,
};

InputField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  padding: PropTypes.bool,
  paddingText: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default InputField;
