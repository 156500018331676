export const INPUT_FIELD = 'INPUT_FIELD';
export const INPUT_MASK_FIELD = 'INPUT_MASK_FIELD';
export const TEXT_AREA_FIELD = 'TEXT_AREA_FIELD';
export const PASSWORD_FIELD = 'PASSWORD_FIELD';
export const PHONE_INPUT_FIELD = 'PHONE_INPUT_FIELD';
export const DROPDOWN_FIELD = 'DROPDOWN_FIELD';
export const CHECKBOX_FIELD = 'CHECKBOX_FIELD';
export const RADIO_GROUP_FIELD = 'RADIO_GROUP_FIELD';
export const RICH_TEXT_FIELD = 'RICH_TEXT_FIELD';
export const SOCIAL_LINKS_FIELD = 'SOCIAL_LINKS_FIELD';
