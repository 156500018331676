import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="container max-w-4xl mx-auto px-4 md:px-8 flex flex-wrap justify-between">
        <div className="w-full md:w-6/12 pr-4">
          <h1>Racetime India</h1>
          <p>
            Racetime India is a premier race timing company based in Bangalore, India. We provide
            disposable chip timing services/re-usable triathlon chips for a variety of different
            sporting events ranging from 1-mile to 100 miles and everywhere in between.
          </p>
        </div>
        <div className="w-full mt-10 md:mt-0 md:w-6/12 pl-0 md:pl-4">
          <div className="flex flex-wrap items-top justify-end">
            <div className="w-6/12 md:w-5/12 lg:w-4/12">
              <h2>Links</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="w-6/12 md:w-5/12 lg:w-4/12">
              <h2>Resources</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-10 md:mt-6">
        <span className="text-sm font-medium">© Racetime India {new Date().getFullYear()}</span>
        <span className="text-base pr-1 pl-1">•</span>
        <p className="text-sm font-medium">Version: {process.env.REACT_APP_VERSION}</p>
      </div>
    </footer>
  );
}

export default Footer;
