import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';

import SuccessAnimation from '../../../assets/lottie/success.json';

function RaceSuccess() {
  const navigate = useNavigate();

  const { url } = useParams();

  return (
    <>
      <Helmet>
        <title>Registration Success</title>
      </Helmet>
      <div className="min-height">
        <div className="max-mobile-width sm:max-w-lg w-full mx-auto px-4 py-6 md:px-8">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl text-left font-bold ml-3">Registration Successful</h1>
            <p className="text-center mt-5 mb-3">
              Thank you! We have received your registration. An registration confirmation email has
              been sent to the registered email address.
            </p>
            <Lottie className="w-40 h-40" animationData={SuccessAnimation} loop={false} />
            <div className="flex flex-row justify-center w-full mt-2">
              <button
                type="button"
                className="button mr-5"
                onClick={() => navigate(`/race/${url}`)}>
                Race Details
              </button>
              <button
                type="button"
                className="button"
                onClick={() => navigate(`/race/${url}/register`)}>
                Register More?
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RaceSuccess;
