/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parse } from 'date-fns';
import classNames from 'classnames';

import {
  fetchProfile,
  updateAccount,
  updateEmergency,
  uploadProfileImage,
} from '../../../services/apis/directory/user';

import { updateUser, refreshSession } from '../../../services/cognito';

import { sessionActive } from '../../../redux/reducer/session';

import Spinner from '../../../components/loader/spinner';

import Sidebar from '../../../components/user/profile/sidebar';
import Account from '../../../components/user/profile/content/account';
import Emergency from '../../../components/user/profile/content/emergency';
import Registrations from '../../../components/user/profile/content/registrations';

import Tabs from '../../../data/tabs/profile/index.json';

function Profile() {
  const { user, refreshToken } = useSelector(state => state.session);
  const dispatch = useDispatch();

  const [active, setActive] = useState(0);
  const [account, setAccount] = useState(null);
  const [emergency, setEmergency] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  const profile = useCallback(async () => {
    const response = await fetchProfile();

    setAccount(response.account);
    setEmergency(response.emergency);
    setLoading(false);
  }, []);

  useEffect(() => {
    profile().catch(error => {
      setLoading(false);

      throw error;
    });
  }, [profile]);

  const sessionRefresh = useCallback(async () => {
    try {
      const session = await refreshSession(refreshToken);

      const newRefreshToken = session.getRefreshToken().getToken();
      const newAccessToken = session.getAccessToken().getJwtToken();
      const newIdToken = session.getIdToken().getJwtToken();
      const newUser = session.getIdToken().payload;

      dispatch(
        sessionActive({
          refreshToken: newRefreshToken,
          accessToken: newAccessToken,
          idToken: newIdToken,
          user: {
            id: newUser.sub,
            firstName: newUser.given_name,
            lastName: newUser.family_name,
            email: newUser.email,
            emailVerified: newUser.email_verified,
            phoneNumber: newUser.phone_number,
            phoneNumberVerified: newUser.phone_number_verified,
            dob: parse(newUser.birthdate, 'dd/MM/yyyy', new Date()),
            gender: newUser.gender,
            picture: newUser.picture || null,
          },
        }),
      );

      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  }, [refreshToken]);

  const updateCognitoUser = useCallback(async attributeList => {
    try {
      const response = await updateUser(attributeList);

      if (response === 'SUCCESS') {
        await sessionRefresh();
      }
    } catch (error) {
      setUpdating(false);
    }
  }, []);

  const accountUpdate = useCallback(
    async data => {
      setUpdating(true);

      try {
        const response = await updateAccount(data);

        const attributeList = [];

        const dataGivenName = {
          Name: 'given_name',
          Value: response.firstName,
        };

        const dataFamilyName = {
          Name: 'family_name',
          Value: response.lastName,
        };

        const dataBirthdate = {
          Name: 'birthdate',
          Value: response.dob,
        };

        const dataGender = {
          Name: 'gender',
          Value: response.gender,
        };

        const dataAddress = {
          Name: 'address',
          Value: response.address,
        };

        attributeList.push(dataGivenName);
        attributeList.push(dataFamilyName);
        attributeList.push(dataBirthdate);
        attributeList.push(dataGender);
        attributeList.push(dataAddress);

        await updateCognitoUser(attributeList);

        setAccount({
          ...account,
          ...response,
        });
      } catch (error) {
        setUpdating(false);
      }
    },
    [account],
  );

  const emergencyUpdate = useCallback(async data => {
    setUpdating(true);

    try {
      const response = await updateEmergency(data);

      setEmergency(response);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  }, []);

  const profileImageUpload = useCallback(async data => {
    setUploading(true);

    try {
      const response = await uploadProfileImage(data);

      const attributeList = [];

      const dataPicture = {
        Name: 'picture',
        Value: response.url,
      };

      attributeList.push(dataPicture);

      await updateCognitoUser(attributeList);

      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  }, []);

  return (
    <div className="container max-w-4xl mx-auto px-4 pt-10 pb-10">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <Sidebar
            user={user}
            uploading={uploading}
            onImageChange={data => profileImageUpload(data)}
          />
        </div>
        <div className="col-span-12 md:col-span-8">
          <div className="tabs bg-white shadow-lg rounded-md border border-gray-300">
            {loading ? (
              <div className="py-3">
                <Spinner size="medium" />
              </div>
            ) : (
              <>
                <ul className="tabs-list no-scrollbar">
                  {Tabs.map((item, index) => (
                    <li className={classNames(index === active ? 'active' : '')} key={index}>
                      <button type="button" onClick={() => (updating ? null : setActive(index))}>
                        {item.label}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="py-4">
                  {active === 0 ? (
                    <Account
                      data={account}
                      loading={updating}
                      onUpdateClick={data => accountUpdate(data)}
                    />
                  ) : active === 1 ? (
                    <Emergency
                      data={emergency}
                      loading={updating}
                      onUpdateClick={data => emergencyUpdate(data)}
                    />
                  ) : (
                    <Registrations />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
