/* eslint-disable func-names */
import { all } from 'redux-saga/effects';

import notificationSaga from './notification';
import cognitoSaga from './cognito';

const rootSaga = function* () {
  yield all([notificationSaga(), cognitoSaga()]);
};

export default rootSaga;
