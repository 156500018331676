/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Mobile({ open, toggleOpen, links }) {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        `mobile mobile${open ? '--open' : '--close'} flex items-center justify-center`,
      )}>
      <ul className="uppercase">
        {links.map(
          (item, index) =>
            !item.hide && (
              <li key={index} className="text-center">
                <button
                  type="button"
                  className="text-black text-2xl uppercase font-semibold"
                  onClick={() => {
                    toggleOpen();
                    navigate(item.link);
                  }}>
                  {item.name}
                </button>
              </li>
            ),
        )}
      </ul>
    </div>
  );
}

Mobile.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
};

export default Mobile;
