/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import Mobile from '../mobile';
import Profile from '../profile';

import Links from '../../../data/components/navigation/header/links.json';

function Header() {
  const navigate = useNavigate();

  const { user, logged, loading } = useSelector(state => state.session);

  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });

  const [mobileOpen, setMobileOpen] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const newLinks = cloneDeep(Links);

    setLinks(
      newLinks.map(item => {
        if (item.hide) {
          if (logged) {
            item.hide = !item.hide;

            return item;
          }
        }

        return item;
      }),
    );
  }, [logged]);

  return (
    <header className="bg-white shadow-xl h-16 sticky top-0 z-10">
      <div className="container max-w-4xl mx-auto px-4 md:px-6 flex items-center justify-between min-h-full">
        <div className="flex items-center">
          {isMobile && (
            <div
              className={classNames(
                `hamburger-menu ${mobileOpen ? 'hamburger-menu--open' : ''} mr-1 z-30`,
              )}
              onClick={() => setMobileOpen(!mobileOpen)}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0">
              <span />
              <span />
              <span />
              <span />
            </div>
          )}
          <a href="/">
            <img
              className="logo p-2 cursor-pointer"
              src="/assets/images/logo.png"
              alt="Racetime Logo"
            />
          </a>
        </div>
        <nav className="flex items-center">
          {!isMobile ? (
            <ul className="flex uppercase">
              {links.map(
                (item, index) =>
                  !item.hide && (
                    <li key={index} className="ml-2">
                      <Link to={item.link} className="text-black text-sm font-bold">
                        {item.name}
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          ) : (
            <Mobile open={mobileOpen} toggleOpen={() => setMobileOpen(!mobileOpen)} links={links} />
          )}
          {loading ? null : !logged ? (
            <button className="button ml-4" type="button" onClick={() => navigate('/login')}>
              Sign In
            </button>
          ) : (
            <div className="ml-3">
              <Profile name={`${user.firstName} ${user.lastName}`} profileImage={user.picture} />
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
