/* eslint-disable func-names */
import { parse } from 'date-fns';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { getUser, getSession, logoutUser } from '../../../services/cognito';

import { GET_SESSION_SAGA, LOGOUT_USER_SAGA } from './types';
import { sessionActive, sessionInactive } from '../../reducer/session';

const getSessionSaga = function* () {
  try {
    const user = yield getUser();

    if (user) {
      const session = yield getSession(user);

      const userSession = session;

      const refreshToken = userSession.getRefreshToken().getToken();
      const accessToken = userSession.getAccessToken().getJwtToken();
      const idToken = userSession.getIdToken().getJwtToken();
      const { payload } = userSession.getIdToken();

      yield put({
        type: sessionActive,
        payload: {
          refreshToken,
          accessToken,
          idToken,
          user: {
            id: payload.sub,
            firstName: payload.given_name,
            lastName: payload.family_name,
            email: payload.email,
            emailVerified: payload.email_verified,
            phoneNumber: payload.phone_number,
            phoneNumberVerified: payload.phone_number_verified,
            dob: parse(payload.birthdate, 'dd/MM/yyyy', new Date()),
            gender: payload.gender,
            picture: payload.picture || null,
          },
        },
      });
    } else {
      yield put({
        type: sessionInactive,
      });
    }
  } catch (error) {
    yield put({
      type: sessionInactive,
    });
  }
};

const logoutUserSaga = function* () {
  yield logoutUser();
  yield put({
    type: sessionInactive,
  });
};

const watchSessionSaga = function* () {
  yield takeEvery(GET_SESSION_SAGA, getSessionSaga);
  yield takeEvery(LOGOUT_USER_SAGA, logoutUserSaga);
};

const cognitoSaga = function* () {
  yield all([fork(watchSessionSaga)]);
};

export default cognitoSaga;
