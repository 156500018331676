/* eslint-disable func-names */
import * as yup from 'yup';
import { parse, isValid, differenceInYears } from 'date-fns';

import {
  INPUT_FIELD,
  INPUT_MASK_FIELD,
  TEXT_AREA_FIELD,
  RADIO_GROUP_FIELD,
  DROPDOWN_FIELD,
} from '../../../../constants/form/types';

import {
  FIELD_REQUIRED,
  INVALID_DATE,
  MINIMUM_AGE_ERROR,
  MAXIMUM_AGE_ERROR,
} from '../../../../constants/validation/errors';

import Gender from '../../../../data/master/gender.json';
import BloodGroup from '../../../../data/master/bloodGroup.json';
import ShirtSize from '../../../../data/master/shirtSize.json';

const Account = {
  YupSchema: yup.object().shape({
    firstName: yup.string().required(FIELD_REQUIRED),
    lastName: yup.string().required(FIELD_REQUIRED),
    dob: yup
      .string()
      .required(FIELD_REQUIRED)
      .test('date-validate', null, function (value) {
        const parsedDate = parse(value, 'dd/MM/yyyy', new Date());

        if (isValid(parsedDate)) {
          const years = differenceInYears(new Date(), parsedDate) || 0;

          if (years < Number(process.env.REACT_APP_MINIMUM_AGE)) {
            return this.createError({
              path: this.path,
              message: `${MINIMUM_AGE_ERROR} ${Number(process.env.REACT_APP_MINIMUM_AGE)}`,
            });
          }

          if (years > Number(process.env.REACT_APP_MAXIMUM_AGE)) {
            return this.createError({
              path: this.path,
              message: `${MAXIMUM_AGE_ERROR} ${Number(process.env.REACT_APP_MAXIMUM_AGE)}`,
            });
          }

          return true;
        }

        return this.createError({
          path: this.path,
          message: INVALID_DATE,
        });
      }),
    gender: yup.string().required(FIELD_REQUIRED),
    address: yup.string().required(FIELD_REQUIRED),
  }),
  DefaultValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    address: '',
    bloodGroup: null,
    shirtSize: null,
    runningGroup: '',
    corporateGroup: '',
  },
  Template: {
    fields: [
      {
        type: INPUT_FIELD,
        name: 'firstName',
        label: 'First Name',
        required: true,
      },
      {
        type: INPUT_FIELD,
        name: 'lastName',
        label: 'Last Name',
        required: true,
      },
      {
        type: INPUT_FIELD,
        name: 'email',
        label: 'Email',
        disabled: true,
      },
      {
        type: INPUT_MASK_FIELD,
        name: 'phone',
        label: 'Phone Number',
        mask: '9999999999',
        disabled: true,
      },
      {
        type: INPUT_MASK_FIELD,
        name: 'dob',
        label: 'Date Of Birth (DD/MM/YYYY)',
        mask: '99/99/9999',
        required: true,
      },
      {
        type: RADIO_GROUP_FIELD,
        name: 'gender',
        label: 'Gender',
        options: Gender,
        required: true,
      },
      {
        type: TEXT_AREA_FIELD,
        name: 'address',
        label: 'Address',
        required: true,
      },
      {
        type: DROPDOWN_FIELD,
        name: 'bloodGroup',
        label: 'Blood Group',
        options: BloodGroup,
      },
      {
        type: DROPDOWN_FIELD,
        name: 'shirtSize',
        label: 'Shirt Size',
        options: ShirtSize,
      },
      {
        type: INPUT_FIELD,
        name: 'runningGroup',
        label: 'Running Group',
      },
      {
        type: INPUT_FIELD,
        name: 'corporateGroup',
        label: 'Corporate Group',
      },
    ],
  },
};

export default Account;
