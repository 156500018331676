/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import FormBuilder from '../../../../../helper/formBuilder';

import ConsentForm from '../../../../../data/general/userConsent.json';

function Consent(props) {
  const { control } = props;

  return (
    <div className="bg-white shadow-lg rounded-md border border-gray-300 px-6 pt-6 pb-8 sm:pb-6 mt-4">
      <h1 className="mb-4 text-lg font-bold">User Consent</h1>
      <p className="text-base mb-3">
        <ol className="novoHealth-ol">
          <li>
            Provide my consent, for processing of my personal data by you for purposes as specified
            in the{' '}
            <a
              href="https://www.novohealthatembassyreit.com/privacypolicy/PRIVACY%20NOTICE%20Updated.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="underline">
              Privacy Policy
            </a>
          </li>
          <li>
            Declare that I wish to register for Novohealth @ Embassy Manyata and that I don&apos;t
            have any medical conditions that may affect my participation. I understand that I will
            be participating at my own risk, and that the organizers will not be held liable or
            responsible for any injury, loss, damage or any consequence due to my participation in
            any or all events.
          </li>
          <li>
            Agree that I release the Novohealth @ Embassy Manyata organizers against any liability
            for providing, or failing to provide, any first aid care or emergency medical treatment
            and I understand that I have the right to opt out of medical treatment if I so choose.
            However, if I am unable to indicate that I do not wish to receive medical treatment,
            medical treatment will be rendered to me.
          </li>
        </ol>
      </p>
      <div className="grid grid-cols-12 gap-5 row-gap-5">
        {FormBuilder({
          template: {
            fields: ConsentForm.template,
          },
          control,
        }).map(({ field }, index) => (
          <div key={index} className="col-span-12">
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

Consent.propTypes = {
  control: PropTypes.any.isRequired,
};

export default Consent;
